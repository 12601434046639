import classNames from 'classnames'
import ChevronIcon from 'components/icons/ChevronUpIcon'
import ChevronDoubleIcon from 'components/icons/ChevronDoubleIcon'
import React from 'react'

type Props = {
  children: React.ReactNode
  className?: string
  onClick?: () => void
  sortable?: boolean | undefined
  direction?: 'asc' | 'desc' | undefined
}

const Heading = ({children, className, onClick, sortable, direction}: Props) => {

  const RenderChevron = () => {
    return (
      <>
        {
          direction !== undefined
            ? <ChevronIcon className={classNames('transition rotate-180 w-4 h-4 p-0.5 inline mb-0.5', { '-rotate-0': direction === 'desc' } )} />
            : <ChevronDoubleIcon className="w-4 h-4 inline mb-0.5" />
        }
      </>
    )
  }

  return (
    <th className={classNames('font-medium bg-table-header first:pl-4 last:pr-4 text-left py-4 first:rounded-tl-lg last:rounded-tr-lg', sortable ? 'cursor-pointer' : 'cursor-default', className)} onClick={onClick}>
      {
        sortable
          ? <button className="pr-4 outline-offset-4 outline-primary min-w-max lg:pr-0"> {children} {sortable && <RenderChevron />} </button>
          : children
      }
    </th>
  )
}

export default Heading