import { Transition } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'
import { useAuthenticateDeviceMutation, useMfaSendMutation, useMfaVerifyAssetDeviceMutation, useViewAssetQuery } from 'api'
import { setScannedAsset } from 'slices/asset'
import { RootState } from 'store'
import { Title } from 'components/headings'
import Button from 'components/button'
import Card from 'components/cards/default'
import Phone, { PhoneInputProps } from 'components/form/phone'
import MfaForm from 'components/forms/mfa'
import Spinner from 'components/spinner'
import useErrorHandler from 'hooks/useErrorHandler'
import Alert from 'components/alerts'
import RequestAccessModal from 'components/modals/forms/asset_request_access/RequestAccessModal'
import ExpectedMobileErrors from 'enums/ExpectedMobileError'
import { getThemeLogo } from 'helpers/theme'
import pdfAcrobatSvg from 'resources/acrobat-reader.svg'
import ArrowTopRightIcon from 'components/icons/ArrowTopRightIcon'
import TimeConfig from 'config/time'
import { LocationState } from 'types/locationState'
import MfaRequestContexts from 'enums/MfaRequestContexts'

type FormInputs = {
  country_code: string
  phone: string
}

const MainPage = () => {
  const dispatch = useDispatch()
  const errorHandler = useErrorHandler()
  const state = useLocation().state as LocationState
  const previousLocation = state?.from ?? null

  const [searchParams] = useSearchParams()

  const [guestAuthenticated, setGuestAuthenticated] = useState<boolean>(false)
  const [isMfaFormOpen, setMfaFormOpen] = useState<boolean>(false)
  const [accessModal, setAccessModal] = useState<boolean>(false)

  const { assetType, assetUid, assetSpec } = useMemo(() => ({
    assetType: searchParams.get('t') || undefined,
    assetSpec: searchParams.get('v') || undefined,
    solutionId: searchParams.get('s'),
    assetUid: searchParams.get('d'),
  }), [searchParams])

  const [sendCode] = useMfaSendMutation()

  const [mfaVerification, {
    data: mfaResult,
    error: mfaError,
    isLoading: isMfaLoading,
  }] = useMfaVerifyAssetDeviceMutation()

  const [deviceCheck, {
    isSuccess: isDeviceAuthenticated,
    error: deviceAuthError,
    isLoading: isDeviceLoading,
  }] = useAuthenticateDeviceMutation()

  const {
    data: asset,
    error: getAssetError,
    isLoading: isAssetLoading,
  } = useViewAssetQuery(
    {
      id: assetUid!,
      type: assetType,
      spec: assetSpec,
    },
    {
      skip: !assetUid,
      pollingInterval: TimeConfig.FIVE_MINUTES,
    }
  )

  const {
    watch,
    setValue,
    setError,
    getValues,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<FormInputs>()

  const {
    currentUser,
    scannedAsset,
  } = useSelector((state: RootState) => ({
    currentUser: state.auth.user,
    scannedAsset: state.asset.scanned_item,
  }))

  const onSubmit = (data: FormInputs) => {
    if (!scannedAsset) {
      return
    }

    deviceCheck({
      id: scannedAsset?.masked_uid,
      body: data,
    })
  }

  useEffect(() => {
    if (asset) {
      if (asset?.redirect_url) {
        if (window.location.href !== asset.redirect_url && process.env.REACT_APP_ENV !== 'development') {
          window.location.replace(asset.redirect_url)
        }
      }

      dispatch(setScannedAsset(asset.asset))
    }
  }, [asset])

  useEffect(() => {
    if (deviceAuthError) {
      errorHandler(deviceAuthError, setError)
      setGuestAuthenticated(false)
    }
  }, [deviceAuthError])

  useEffect(() => {
    if (isDeviceAuthenticated) {
      setMfaFormOpen(true)
      sendCode({
        context: MfaRequestContexts.ASSET_DEVICE_AUTH,
        country_code: getValues('country_code'),
        phone: getValues('phone'),
        masked_uid: assetUid ?? undefined,
      })
    }
  }, [isDeviceAuthenticated])

  useEffect(() => {
    if (mfaResult) {
      dispatch(setScannedAsset(mfaResult.asset))
      setGuestAuthenticated(true)
    }
  }, [mfaResult])

  const handlePhoneOnChange = (phone: PhoneInputProps) => {
    setValue('phone', phone.phone)
    setValue('country_code', phone.country_code)

    if (errors.phone || errors.country_code) {
      clearErrors(['country_code', 'phone'])
    }
  }

  // Queried on server
  const activeMessage = useMemo(() => {
    const activeAsset = mfaResult?.asset || asset?.asset

    if (!activeAsset || !activeAsset?.messages) {
      return
    }

    const defaultMessage = activeAsset.messages.find((message) => message.is_default)

    // Load active message if available
    if (activeAsset?.messages?.length > 1) {
      return activeAsset.messages.find((message) => !message.is_default)
    }

    return defaultMessage
  }, [asset, mfaResult])

  // If there is a redirect and no asset - redirect
  if (!asset?.asset && asset?.redirect_url) {
    return (
      <Card className="max-w-lg px-8 py-12 text-center">
        <span className="text-lg font-medium">Redirecting...</span>
      </Card>
    )
  }

  return (
    <div className="flex flex-col flex-grow h-full">
      {
        !asset &&
        !isAssetLoading &&
        !getAssetError &&
        <Card className="max-w-lg px-8 py-12 text-center min-h-min">
          <h1 className="mb-3 text-xl font-bold homepage-header text-primary-gray">Welcome to Ki™Call</h1>
          <div className="space-y-3 text-sm text-gray-500 homepage-body">
            <p>If you want to know more about how we can help securely deliver your critical information right at the point of need then get in touch using the <a href="mailto:enquiries@keysafe.co.uk" className="url-text">enquiries@keysafe.co.uk</a></p>
            <p>If you are already a Ki™Call customer then login to your account below</p>
          </div>
          <Link to='/login' state={{ from: previousLocation }}>
            <Button variant="primary" className="w-full mx-auto mt-10 text-sm">
              Login
            </Button>
          </Link>
        </Card>
      }

      {
        isAssetLoading &&
        <div className="m-auto h-fit">
          <Spinner />
        </div>
      }

      {
        getAssetError &&
        <Card className="max-w-md py-10 pb-20">
          <div className="my-auto text-center">
            <Title>KiCall Not Found</Title>
            <p>There was a problem accessing this KiCall</p>
          </div>
        </Card>
      }

      {
        asset &&
        <>
          {
            !asset?.asset?.user_id && !asset?.asset?.is_private &&
            <Card className="max-w-lg">
              <div className="my-auto text-center">
                <Title>KiCall not registered</Title>
                <p>
                  {
                    currentUser
                      ? 'You can register this KiCall using the account you are currently logged into'
                      : 'You can register this KiCall with a new or existing account'
                  }
                </p>
              </div>

              <div className="flex flex-col gap-2 mt-auto md:mt-10">
                {
                  currentUser
                    ? <Button block variant="secondary" href="/asset/register">Register KiCall</Button>
                    : <>
                      <Button block href={`/register/step-one?asset=${asset?.asset?.masked_uid}`}>Register with new account</Button>
                      <Button block variant="secondary" href="/login">Register with existing account</Button>
                    </>
                }
              </div>
            </Card>
          }

          <Transition
            show={(asset?.asset?.is_private === false && asset?.asset?.user_id !== null) || guestAuthenticated}
            className="flex flex-col gap-2"
            enter="ease-out duration-500"
            enterFrom="opacity-0 scale-95"
            enterTo="translate-y-0 scale-100"
            leave="ease-in duration-400"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <>
              <Card isGlass className="py-4">

                <img
                  src={getThemeLogo(asset?.asset)}
                  alt="theme-img"
                  className="w-full max-h-[12.5rem] max-w-[21.875rem] mx-auto object-contain mb-4"
                />

                <div
                  dangerouslySetInnerHTML={{ __html: activeMessage?.content ?? '' }}
                  className={`quill-message ql-editor px-4 pt-4 pb-8 border-gray-500 bg-white ${asset?.asset?.files?.length > 0 ? 'rounded-t border-x-[0.0313rem] border-t-[0.0313rem]' : 'rounded border-[0.0313rem]'}`}
                />

                {
                  asset?.asset?.files.length > 0 &&
                  <div className="bg-white pb-8 border-gray-500 rounded-b border-x-[0.0313rem] border-b-[0.0313rem]">
                    <hr className="w-1/2 mx-auto mb-4" />
                    <div className="flex flex-col gap-3">
                      {
                        asset?.asset?.files.map((file) => (
                          <a key={`asset-files-${file.id}`} href={file.attachment.url} target={file.pivot.new_tab ? '_blank' : '_self'} className="flex items-center gap-3 mx-4 outline-offset-4 outline-primary group" rel="noreferrer">

                            <div>
                              <img
                                src={pdfAcrobatSvg}
                                alt="adobe-acrobat-svg"
                                className="h-9 lg:h-12 min-w-[2.25rem] lg:min-w-[3rem] aspect-square"
                              />
                            </div>

                            <p className="text-sm font-medium break-word group-hover:underline group-focus:underline">{file.name}</p>

                            <div className="ml-auto mr-3">
                              <ArrowTopRightIcon className="lg:h-5 h-4 lg:min-w-[1.25rem] min-w-[1rem] aspect-square" />
                            </div>

                          </a>
                        ))
                      }
                    </div>
                  </div>
                }

              </Card>
              <Button variant="primary" href="/login" className="w-full max-w-xs mx-auto mt-8 text-sm lg:max-w-none">Login as Owner</Button>
            </>
          </Transition>

          <Transition
            show={asset?.asset?.user_id !== null && asset?.asset?.is_private === true && !guestAuthenticated}
            className="flex flex-col h-full gap-2"
            enter="ease-out duration-500"
            enterFrom="opacity-0 scale-95"
            enterTo="translate-y-0 scale-100"
            leave="ease-in duration-400"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="h-full m-0 lg:h-auto">
              {
                isMfaFormOpen
                  ? <MfaForm
                    assetUid={scannedAsset?.masked_uid ?? undefined}
                    countryCode={watch('country_code')}
                    phone={watch('phone')}
                    context={MfaRequestContexts.ASSET_DEVICE_AUTH}
                    onSubmit={(data) => mfaVerification(data)}
                    onSubmitError={mfaError}
                    isLoading={isMfaLoading} />
                  : (
                    <form onSubmit={handleSubmit(onSubmit)} className="justify-between h-full gap-2 lg:h-auto lg:my-auto lg:justify-start">
                      <Card className="flex flex-col w-full max-w-lg gap-2">
                        <Title>KiCall Message</Title>
                        {
                          asset?.asset?.public_message &&
                          <Alert message={asset?.asset?.public_message} type="info" />
                        }
                        <div className="flex flex-col gap-2 mt-6 mb-12">
                          <Phone
                            onChange={(phone) => handlePhoneOnChange(phone)}
                            error={errors?.phone?.message !== ExpectedMobileErrors.NOT_AUTHORISED ? (errors?.phone?.message || errors?.country_code?.message) : undefined}
                          />
                          {
                            errors?.phone?.message === ExpectedMobileErrors.NOT_AUTHORISED &&
                            <p className='text-xs font-medium text-center text-red-500 whitespace-pre-wrap' onClick={() => setAccessModal(true)}>This number is not authorised to view this message. <u className="font-bold cursor-pointer">Request access</u></p>
                          }
                        </div>
                        <div className="flex flex-col gap-2 mt-auto">
                          <Button type="submit" block isLoading={isDeviceLoading}>Authenticate Me</Button>
                          <Button href="/login" block variant="link" className="text-sm">Login as Owner</Button>
                        </div>
                      </Card>
                    </form>
                  )
              }
            </div>
          </Transition>
        </>
      }
      <RequestAccessModal
        isOpen={accessModal}
        onClose={() => setAccessModal(false)}
        defaultPhone={watch('phone')}
        defaultCountryCode={watch('country_code')}
      />
    </div>
  )
}

export default MainPage