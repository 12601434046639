import Asset from 'api/types/models/asset'
import { AssetType } from 'enums/AssetType'
import { QRCodeSVG } from 'qrcode.react'
import { Link } from 'react-router-dom'

type AssetGridCardProps = {
  asset: Asset
}

const AssetGridCard = ({
  asset,
}: AssetGridCardProps) => {
  return (
    <div className="w-full border rounded-md outline-none border-secondary-gray bg-white focus:border-primary shadow-sm">

      <div className="flex mb-2">
        {
          asset.is_private
            ? <p className="inline-block w-full p-px px-2 text-xs font-medium text-center text-red-600 bg-red-100 rounded-t">Private</p>
            : <p className="inline-block w-full p-px px-2 text-xs font-medium text-center text-blue-600 bg-blue-100 rounded-t">Public</p>
        }
      </div>

      <div className="flex justify-center">
        <div className="flex gap-1 mx-2 sm:mx-0">
          <span>Total Scans: {(asset.total_scans || 0).toString().padStart(2, '0')}</span>
        </div>
      </div>

      <Link target="_blank" to={asset.url} className="flex flex-col ">
        <div className="flex justify-center mt-2 mb-3">
          <QRCodeSVG value={asset?.url} className="max-w-[6.5rem] max-h-[6.5rem]" />
        </div>
      </Link>

      <p className="mx-auto font-bold text-black px-2 text-center">
        { asset.description ?? 'KiCall (Not Named)' }
      </p>

      <p className="mx-auto text-center text-xs text-gray-400">
        <code>
          {asset.serial}
        </code>
      </p>

      <div className="flex gap-1 justify-center mb-3">
        <Link className='url-text' to={`/asset/manage/${asset.masked_uid}`}>Edit</Link>
        <p>|</p>
        <Link className='url-text' to={`/asset/manage/${asset.masked_uid}/history`}>History</Link>
      </div>
    </div>
  )
}

export default AssetGridCard