import { Form } from 'components/form/form'
import FormLayout from '../layouts/FormLayout'
import Input from 'components/form/input'
import { z } from 'zod'
import { DeepPartial, FieldValues } from 'react-hook-form'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { SerializedError } from '@reduxjs/toolkit'
import { CreditBlock } from 'api/types/models/credit_block'
import Button from 'components/button'
import { DateTime } from 'luxon'

export type CreditBlockInputs = Omit<CreditBlock, 'id'>

export const creditBlockFormSchema = z.object({
  amount: z.coerce.number().positive({ message: 'Amount must be greater than zero' }),
  purchase_date: z.coerce.date().transform(v => DateTime.fromJSDate(v)),
})

type CreditBlockFormProps<TFormFields extends FieldValues> = {
  onSubmit: (data: TFormFields) => void
  cancelHref?: string
  errors?: FetchBaseQueryError | SerializedError,
  defaultValues?: DeepPartial<CreditBlockInputs>
}

export function CreditBlockForm({ 
  onSubmit, 
  errors,
  cancelHref,
  defaultValues, 
}: CreditBlockFormProps<CreditBlockInputs>) {
  return (
    <FormLayout>
      <Form<CreditBlockInputs, typeof creditBlockFormSchema>
        error={errors}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        validationSchema={creditBlockFormSchema}
      >
        {({ register, formState: { errors } }) => (
          <>
            <FormLayout.Block>
              <FormLayout.Title
                title="Information"
                subtitle="Fill in the fields below to create a new Credit Block"
              />

              <FormLayout.Group
                label="Credits"
                htmlFor="amount"
              >
                <Input
                  {...register('amount')}
                  type="number"
                  placeholder="Enter credit amount"
                  error={errors?.amount?.message}
                />
              </FormLayout.Group>

              <FormLayout.Group
                label="Purchase Date"
                htmlFor="purchase_date"
              >
                <Input
                  type="date"
                  placeholder="Enter purchase date"
                  {...register('purchase_date')}
                  error={errors?.purchase_date?.message}
                />
              </FormLayout.Group>
            </FormLayout.Block>

            <FormLayout.Footer>
              <Button href={cancelHref} variant="secondary">
                Cancel
              </Button>

              <Button type="submit">
                Create Block
              </Button>
            </FormLayout.Footer>
          </>
        )}
      </Form>
    </FormLayout>
  )
}