// Needs to match backend
export enum AssetSpecs {
  Gen2 = '2',
  Gen3 = '3'
}

enum FriendlyAssetSpecs {
  Gen2 = 'Generation 2',
  Gen3 = 'Generation 3',
  Unknown = 'Unknown'
}

export function getAssetSpecName(spec: AssetSpecs | undefined) {
  switch (spec) {
    case AssetSpecs.Gen2:
      return FriendlyAssetSpecs.Gen2
    case AssetSpecs.Gen3:
      return FriendlyAssetSpecs.Gen3
    case undefined:
      return FriendlyAssetSpecs.Unknown
  }
}