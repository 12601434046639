import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGetMeQuery } from './api'
import { login, logout } from './slices/auth'
import { NotifierContextProvider } from 'react-headless-notifier'
import Spinner from 'components/spinner'
import { RootState } from 'store'

const notifyConfig = {
  position: 'top',
  duration: 5000,
}

function App({ children }: { children: JSX.Element }) {
  const dispatch = useDispatch()

  const { data: loggedInUser, isError: isErrorMe, isLoading: isLoadingMe } = useGetMeQuery()

  const { isAuthenticated } = useSelector((state: RootState) => ({
    isAuthenticated: state.auth.authenticated,
  }))

  useEffect(() => {
    if (isErrorMe) {
      dispatch(logout())
    } else if (loggedInUser) {
      dispatch(login(loggedInUser.user))
    }
  }, [dispatch, loggedInUser, isErrorMe, isLoadingMe])

  if (isAuthenticated === null || isLoadingMe) {
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <Spinner className="text-primary-gray" />
      </div>
    )
  }

  return (
    <NotifierContextProvider config={notifyConfig}>
      <div className="h-full App">
        {children}
      </div>
    </NotifierContextProvider>
  )
}

export default App
