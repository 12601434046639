import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, EndpointBuilder } from '@reduxjs/toolkit/query/react'
import AdminLoginRequest from '../../types/requests/auth/admin/login'
import QueryTags from '../../types/QueryTags'
import { LoginResponse } from 'api/types/response/auth/admin/login'
import { GetTfaResponse, PostChallengeTfaResponse, PostVerifyTgaResponse } from 'api/types/response/auth/admin/tfa'
import { GetTfaRequest, PostChallengeTfaRequest, PostVerifyTfaRequest } from 'api/types/requests/auth/admin/tfa'

const userAuthEndpoints = (builder: EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>, QueryTags, 'data'>) =>
  ({
    adminLogin: builder.mutation<LoginResponse, AdminLoginRequest>({
      query: (body) => ({
        url: '/auth/admin/login',
        method: 'post',
        body,
      }),
    }),

    adminLogout: builder.mutation<void, void>({
      query: () => ({
        url: '/auth/logout',
        method: 'post',
      }),
    }),

    adminTfa: builder.query<GetTfaResponse, GetTfaRequest>({
      query: () => '/auth/admin/tfa',
    }),

    adminVerifyTfa: builder.mutation<PostVerifyTgaResponse, PostVerifyTfaRequest>({
      query: (body) => ({
        url: '/auth/admin/tfa',
        method: 'post',
        body,
      }),
    }),

    adminChallengeTfa: builder.mutation<PostChallengeTfaResponse, PostChallengeTfaRequest>({
      query: (body) => ({
        url: '/auth/admin/tfa',
        method: 'post',
        body,
      }),
    }),
  })

export default userAuthEndpoints