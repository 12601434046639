import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { createApi } from '@reduxjs/toolkit/query/react'
import { logout } from '../slices/auth'
import userAuthEndpoints from './endpoints/user/auth'
import userAssetEndpoints from './endpoints/user/asset'
import userDeviceEndpoints from './endpoints/user/device'
import adminAuthEndpoints from './endpoints/admin/auth'
import adminAssetEndpoints from './endpoints/admin/asset'
import adminThemeEndpoints from './endpoints/admin/theme'
import adminDeviceEndpoints from './endpoints/admin/device'
import adminUserEndpoints from './endpoints/admin/user'
import loqateEndpoints from './endpoints/loqate/loqate'
import adminFileEndpoints from './endpoints/admin/file'
import productEndpoints from './endpoints/admin/products'

const prepareHeaders = (headers: Headers) => {
  const match = document.cookie.match(new RegExp('(^|;\\s*)(XSRF-TOKEN)=([^;]*)'))
  if (match) {
    headers.set('X-XSRF-TOKEN', decodeURIComponent(match[3]))
  }

  return headers
}

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  credentials: 'include',
  prepareHeaders,
})

const baseQueryIntercept: typeof baseQuery = async (args, api, extraOptions) => {
  const queryResult = await baseQuery(args, api, extraOptions)
  if (queryResult.error && queryResult.error?.status === 401 && api.endpoint !== 'getMe') {
    api.dispatch(logout())
  }

  return queryResult
}

const dataApi = createApi({
  reducerPath: 'data',
  tagTypes: [
    'auth',
    'asset',
    'assets',
    'clients',
    'client',
    'credit_blocks',
    'configurations',
    'credit_logs',
    'messages',
    'message',
    'user',
    'users',
    'admin_files',
    'admin_themes',
    'admin_access_request',
    'admin_assets',
    'admin_asset',
    'admin_devices',
    'admin_device',
    'admin_messages',
    'admin_message',
    'devices',
  ],
  baseQuery: baseQueryIntercept,
  endpoints: (builder) => ({
    ...adminAuthEndpoints(builder),
    ...adminAssetEndpoints(builder),
    ...adminDeviceEndpoints(builder),
    ...adminFileEndpoints(builder),
    ...adminThemeEndpoints(builder),
    ...adminUserEndpoints(builder),
    ...userAuthEndpoints(builder),
    ...userAssetEndpoints(builder),
    ...userDeviceEndpoints(builder),
    ...loqateEndpoints(builder),
    ...productEndpoints(builder),
  }),
})

export default dataApi

export const {
  // User Auth
  useGetMeQuery,
  useLoginMutation, useLogoutMutation, useRegisterMutation,
  useUpdateAssetMutation, useMfaVerifyAssetOwnerMutation, useMfaVerifyAssetDeviceMutation,
  useMfaSendMutation, useUpdateAccountMutation,

  // User Assets
  useShowAssetQuery, useViewAssetQuery, useGetUserAssetsQuery,
  useGetAssetEventsQuery,
  useRegisterAssetMutation, useAuthenticateDeviceMutation, useRequestAssetAccessMutation,
  useDeregisterAssetMutation, useDenyAssetRequestMutation,

  // User Devices
  useGetDevicesQuery, useGetDeviceQuery, useStoreDeviceMutation,
  useUpdateDeviceMutation,useDeleteDeviceMutation, useAttachDeviceMutation,
  useDetachDeviceMutation,

  // Admin Auth
  useAdminLoginMutation, useAdminLogoutMutation, useAdminVerifyTfaMutation,
  useAdminChallengeTfaMutation, useAdminTfaQuery,

  // Admin Assets
  useAdminGetAssetsQuery, useAdminGetAssetQuery, useAdminGetAssetEventsQuery,
  useAdminUpdateAssetMutation, useAdminDeregisterAssetMutation,
  useAdminGetAssetMapQuery,

  // Admin Devices
  useAdminGetDevicesQuery, useAdminGetDeviceQuery,
  useAdminStoreDeviceMutation, useAdminUpdateDeviceMutation, useAdminDeleteDeviceMutation,

  // Admin Files
  useAdminGetFilesQuery, useAdminGetUserFilesQuery, useAdminGetAssetFilesQuery,
  useAdminStoreFilesMutation, useAdminUpdateFilesMutation, useAdminUpdateAssetFilesMutation,
  useAdminDeleteUserFilesMutation,

  // Admin Themes
  useAdminGetThemesQuery, useAdminGetThemeQuery, useAdminListThemesQuery,
  useAdminStoreThemeMutation, useAdminUpdateThemeMutation, useAdminDeleteThemeMutation,

  // Admin Users
  useAdminGetUsersQuery, useAdminListUsersQuery,
  useAdminShowUserQuery, useAdminStoreUserMutation, useAdminUpdateUserMutation,
  useAdminDeleteUserMutation,

  // Loqate Endpoints
  useFindAddressQuery, useRetrieveAddressQuery,

  // Product Endpoints
  useGetProductsQuery,
} = dataApi
