import { useAdminTfaQuery, useAdminVerifyTfaMutation } from 'api'
import { PostVerifyTfaInputs } from 'api/types/requests/auth/admin/tfa'
import Button from 'components/button'
import Card from 'components/cards/default'
import { VerifyTfaForm } from 'components/forms/tfa/verify-tfa'
import NotificationToast from 'components/notifications'
import { QrCode } from 'components/qr-code'
import { useState } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { login } from 'slices/auth'
import { PotentialState } from 'router'

const TfaSetupPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { notify } = useNotifier()

  const [promptComplete, setPromptComplete] = useState(false)

  const { data: tfa, isLoading: isTfaLoading } = useAdminTfaQuery()
  const [verifyTfa, { isLoading, error, data: verificationResponse }] = useAdminVerifyTfaMutation()

  const handleOnSubmit = async (data: PostVerifyTfaInputs) => {
    await verifyTfa(data)
      .unwrap()
      .then((response) => {
        dispatch(login(response.data.user))
      })
      .catch((catchError) => {
        if (catchError?.status >= 500) {
          notify(<NotificationToast type="error" message="Failed to enable two-factor authentication, please try again later" />)
        }
      })
  }

  const handleRecoveryCodesComplete = () => {
    notify(<NotificationToast message="Two-factor authentication enabled" />)
    navigate('/admin/dashboard/assets/browse', { state: null })
  }

  if (!(location.state as PotentialState)?.tfa_setup) {
    navigate('/admin/login')
  }

  if (!promptComplete) {
    return (
      <Prompt
        onClick={setPromptComplete}
        isLoading={isTfaLoading}
      />
    )
  }

  // On setup success, show the recovery codes
  if (verificationResponse?.data?.recoveryCodes) {
    const { recoveryCodes } = verificationResponse.data
    return (
      <RecoveryCodes
        onClick={handleRecoveryCodesComplete}
        codes={recoveryCodes}
      />
    )
  }

  return (
    <div className="h-full lg:p-3 bg-slate-200 p-0">
      <div className="flex max-w-md mx-auto">
        <Card className='my-auto'>
          <h3 className='text-lg'>
            Setup Two-Factor Authentication
          </h3>
          <hr className="mt-2 mb-4" />
          <div className="flex flex-col gap-y-10">
            <div className="flex flex-col gap-y-4">
              <div>
                <h4 className='font-medium'>
                  Download authenticator
                </h4>
                <p>
                  In order to enable two-factor authentication you will need to download an authenticator app
                </p>
              </div>
              <div className="flex flex-col gap-y-2">
                <a href="https://support.google.com/accounts/answer/1066447" className="underline underline-offset-4">
                  Google Authenticator
                </a>
                <a href="https://www.microsoft.com/en-gb/security/mobile-authenticator-app" className="underline underline-offset-4">
                  Microsoft Authenticator
                </a>
              </div>
            </div>
            <div>
              <h4 className='font-medium'>
                Scan QR code
              </h4>
              <p>
                Use the scan QR code functionality on your authenticator app to add ARMS Uno
              </p>
              <div className="mt-4">
                {
                  tfa?.data?.qr
                    ? <QrCode value={tfa.data.qr} />
                    : null
                }
              </div>
            </div>

            <VerifyTfaForm
              onSubmit={(data) => handleOnSubmit(data)}
              isLoading={isLoading}
              errors={error}
            />

          </div>
        </Card>
      </div>
    </div>
  )
}

type PromptProps = {
  onClick: (value: boolean) => void
  isLoading: boolean
}

function Prompt({ onClick, isLoading }: PromptProps) {
  return (
    <div className="h-full lg:p-3 bg-slate-200 p-0">
      <div className="flex max-w-md mx-auto">
        <Card>
          <div className="flex flex-col gap-y-8">
            <div>
              <h3 className='text-lg'>
                Two-Factor Authentication
              </h3>
              <hr className="mt-2 mb-4" />
              <p>
                As an extra measure of security, we require all accounts to have a second form of authentication
              </p>
            </div>
            <div className="flex flex-col gap-y-3">
              <Button onClick={() => onClick(true)}
                isLoading={isLoading}>
                Continue
              </Button>
              <Link to="/admin/login"
                className="text-xs text-center underline underline-offset-2">
                Leave this page if you do not want to continue
              </Link>
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}

type RecoveryCodesProps = {
  codes: string[]
  onClick: () => void
}

function RecoveryCodes({ codes, onClick }: RecoveryCodesProps) {
  return (
    <div className="h-full lg:p-3 bg-slate-200 p-0">
      <div className="flex max-w-md mx-auto">
        <Card>
          <div>
            <h3 className='text-lg'>
              Recovery Codes
            </h3>
            <hr className="mt-2 mb-4" />
            <p>
              Store these codes in a safe place. They can be used to recover your account if you lose access to your authenticator app.
            </p>
            <div className="flex flex-col gap-y-8 mt-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 w-full bg-gray-50 rounded-md p-5 text-center">
                {
                  codes.map((code, index) => (
                    <code key={`${index}-recovery-codes`} className='text-sm'>
                      {code}
                    </code>
                  ))
                }
              </div>
              <Button
                block
                onClick={onClick}
              >
                Continue
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default TfaSetupPage
