import { useForm } from 'react-hook-form'
import { useLoginMutation, useMfaVerifyAssetOwnerMutation, useViewAssetQuery } from 'api'
import { useEffect, useMemo, useState } from 'react'
import { login } from 'slices/auth'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { RootState } from 'store'
import Button from 'components/button'
import MfaForm, { MfaFormInputTypes } from 'components/forms/mfa'
import { User } from 'api/types/models/user'
import Card from 'components/cards/default'
import useErrorHandler from 'hooks/useErrorHandler'
import Phone, { PhoneInputProps } from 'components/form/phone'
import { Title } from 'components/headings'
import { LocationState } from 'types/locationState'
import MfaRequestContexts from 'enums/MfaRequestContexts'

type FormInputs = {
  country_code: string,
  phone: string,
}

const LoginPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const errorHandler = useErrorHandler()
  const search = useLocation().search
  const state = useLocation().state as LocationState
  const previousLocation = state?.from

  const urlAssetUid = useMemo(() => new URLSearchParams(search).get('d'), [search])

  const [isMfaFormOpen, setMfaFormOpen] = useState(false)

  const [submitLoginAttempt, { isLoading: isLoginAttemptLoading }] = useLoginMutation()

  const [mfaVerification, {
    data: mfaVerificationResult,
    error: mfaVerificationError,
    isLoading: isMfaVerificationLoading,
  }] = useMfaVerifyAssetOwnerMutation()

  const {
    handleSubmit,
    setValue,
    getValues,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm<FormInputs>()

  const {
    user,
    isAuthenticated,
    scannedAsset,
  } = useSelector((state: RootState) => ({
    user: state.auth.user,
    isAuthenticated: state.auth.authenticated,
    scannedAsset: state.asset.scanned_item,
  }))

  const {
    data: asset,
  } = useViewAssetQuery({ id: scannedAsset?.masked_uid || '' },
    { skip: !scannedAsset?.masked_uid }
  )

  // Redirect the user if already logged in
  useEffect(() => {
    if (isAuthenticated && !mfaVerificationResult && !scannedAsset) {
      navigate('/asset/manage/list')
    }
  }, [isAuthenticated, mfaVerificationResult, navigate, scannedAsset, user])

  const onMfaSuccess = async (user: User) => {
    // If the asset has not been registered
    if (asset?.asset?.user_id === null) {
      return navigate('/asset/register')
    }

    // If the user successfully logs in
    if (user) {
      if (previousLocation) {
        return navigate(previousLocation.pathname + previousLocation.search ?? '')
      } else if (urlAssetUid) {
        return navigate(`/asset/manage/${urlAssetUid}/access`)
      }

      return navigate('/asset/manage/list')
    }

    return
  }

  const handleLoginAttempt = (data: FormInputs) => {
    submitLoginAttempt(data)
      .unwrap()
      .then(() => {
        setMfaFormOpen(true)
      })
      .catch((error) => {
        errorHandler(error, setError)
      })
  }

  const handleMfaVerification = (data: MfaFormInputTypes) => {
    mfaVerification(data)
      .unwrap()
      .then((response) => {
        dispatch(login(response.user))
        onMfaSuccess(response.user)
      })
  }

  const handlePhoneOnChange = (phone: PhoneInputProps) => {
    setValue('phone', phone.phone, { shouldTouch: true })
    setValue('country_code', phone.country_code, { shouldTouch: true })

    if (errors.phone || errors.country_code) {
      clearErrors(['country_code', 'phone'])
    }
  }

  return (
    <div className="flex flex-col w-full h-full max-w-lg mx-auto">
      {
        isMfaFormOpen
          ? <MfaForm
            assetUid={scannedAsset?.masked_uid}
            countryCode={getValues('country_code')}
            phone={getValues('phone')}
            context={MfaRequestContexts.ASSET_OWNER_AUTH}
            onSubmit={(data) => handleMfaVerification(data)}
            onSubmitError={mfaVerificationError}
            isLoading={isMfaVerificationLoading}
          />
          : (
            <Card className="text-center">
              <Title>Login</Title>
              <p className="text-sm text-gray-400">Enter your phone number to login to your account</p>
              <form onSubmit={handleSubmit(handleLoginAttempt)} className="flex flex-col h-full">
                <div className="mt-6 mb-12">
                  <Phone
                    onChange={(phone) => handlePhoneOnChange(phone)}
                    error={errors?.phone?.message || errors?.country_code?.message}
                  />
                </div>
                <div className="mt-auto">
                  <Button block type="submit" isLoading={isLoginAttemptLoading}>Login</Button>
                </div>
              </form>
            </Card>
          )
      }
    </div>
  )
}

export default LoginPage