import { Menu, Transition } from '@headlessui/react'
import { useLogoutMutation } from 'api'
import { DOMAttributes, Fragment, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import UserIcon from 'components/icons/UserIcon'
import { logout } from 'slices/auth'
import { RootState } from 'store'
import logo from 'resources/logo.svg'
import Footer from 'components/footer'
import { getThemeBackground } from 'helpers/theme'
import HamburgerIcon from 'components/icons/HamburgerIcon'
import QrIcon from 'components/icons/QrIcon'
import SupportIcon from 'components/icons/SupportIcon'
import LogoutIcon from 'components/icons/LogoutIcon'
import classNames from 'classnames'
import AdminIcon from 'components/icons/AdminIcon'
import DeviceIcon from 'components/icons/DeviceIcon'

const UserOutlet = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [submitLogout] = useLogoutMutation()

  const { isAuthenticated, user, scannedAsset } = useSelector((state: RootState) => ({
    isAuthenticated: state.auth.authenticated,
    user: state.auth.user,
    scannedAsset: state.asset.scanned_item,
  }))

  const triggerLogout = async () => {
    await submitLogout()
    dispatch(logout())
    navigate('/sign-out')
  }

  return (
    <div className="relative flex flex-col w-full h-full overflow-auto">
      <div className="relative z-10 flex flex-col flex-grow w-full p-4 mx-auto md:max-w-3xl">

        <div className="absolute right-0 z-10 mr-4 lg:mr-0">
          {
            isAuthenticated &&
            <Menu as="div" className="absolute right-0">
              <Menu.Button>
                <HamburgerIcon className="w-8 h-8 rounded-md text-primary-gray" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute shadow-md right-0 flex flex-col bg-white rounded-md focus:outline-primary w-full min-w-[225px] border border-custom-dark-gray">
                  <Link className='text-sm text-primary-gray' to="/account">
                    <div className="flex items-center gap-3 p-2 border-b">
                      <UserIcon className="rounded-md w-9 h-9 bg-secondary-gray text-primary-gray" />
                      <div className="flex flex-col">
                        <span>Hello, {user?.first_name}</span>
                        <Link className='text-sm text-primary-gray' to="/account">View Account</Link>
                      </div>
                    </div>
                  </Link>

                  <div className="flex flex-col py-1">
                    <OutletMenuItem
                      icon={<QrIcon className='w-5 h-5' />}
                      text='KiCalls'
                      to='/asset/manage/list'
                      as='link'
                    />

                    <OutletMenuItem
                      icon={<DeviceIcon className='w-5 h-5' />}
                      text='People'
                      to='/devices'
                      as='link'
                    />

                    <OutletMenuItem
                      icon={<SupportIcon className='w-5 h-5' />}
                      openInNewTab
                      text='Help & Support'
                      to="https://keysafe.co.uk/pages/contact-us"
                      as='external-link'
                    />

                    {
                      user?.is_admin &&
                      <OutletMenuItem
                        icon={<AdminIcon className='w-5 h-5' />}
                        text='Admin Dashboard'
                        to='/admin/dashboard/assets/browse'
                        as='link'
                      />
                    }

                    <OutletMenuItem
                      icon={<LogoutIcon className='w-5 h-5' />}
                      text='Log out'
                      to='/admin/dashboard/assets/browse'
                      variant='danger'
                      as='button'
                      onClick={triggerLogout}
                    />
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          }
        </div>

        <img src={logo} alt="Logo" className="w-32 mx-auto -mt-4 md:w-48" />
        <div className="h-full pb-3 lg:pb-0">
          <Outlet />
        </div>

        {/* Version Footer */}
        <div className="fixed bottom-0 left-0">
          <Footer />
        </div>

      </div>

      {/* Background Image */}
      <div className="fixed inset-0">
        <img src={getThemeBackground(scannedAsset, location.pathname)} alt="background-img" className="object-cover w-full h-full" />
        <div className="absolute inset-0 bg-black/20 backdrop-blur-xl" />
      </div>
    </div>
  )
}

type OutletMenuItemProps = {
  text: string
  to: string
  openInNewTab?: boolean
  variant?: 'primary' | 'danger'
  as: 'button' | 'link' | 'external-link'
  icon: ReactNode
  onClick?: DOMAttributes<HTMLButtonElement>['onClick']
}

const OutletMenuItem = ({ text, to, openInNewTab = false, icon, variant, as, onClick }: OutletMenuItemProps) => {
  const itemClasses = classNames('flex gap-3 px-2 py-[0.3rem] items-center text-primary-gray hover:bg-gray-100', variant === 'danger' && 'font-semibold text-indicator-error')

  switch (as) {
    case 'button':
      return <button className={itemClasses} onClick={onClick}>{icon}{text}</button>
    case 'external-link':
      return <a href={to} target={openInNewTab ? '_blank' : '_self'} className={itemClasses} rel='noreferrer'>{icon}{text}</a>
    case 'link':
      return <Link to={to} target={openInNewTab ? '_blank' : '_self'} className={itemClasses}>{icon}{text}</Link>
  }
}

export default UserOutlet 