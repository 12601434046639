import { cva } from 'class-variance-authority'
import React from 'react'

type FormLayoutProps = {
  children: React.ReactNode,
}

const FormLayout = ({ children }: FormLayoutProps) => {
  return (
    <div className="p-10 space-y-8 divide-y divide-gray-200 sm:space-y-5">
      {children}
    </div>
  )
}

type TitleProps = {
  title: string,
  subtitle?: string
}

const Title = ({ title, subtitle }: TitleProps) => {
  return (
    <div>
      <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
      <p className="max-w-2xl mt-1 text-sm text-gray-500">
        {subtitle}
      </p>
    </div>
  )
}

type BlockProps = {
  children: React.ReactNode,
}

const Block = ({ children }: BlockProps) => {
  return (
    <div className="pt-8 space-y-6 first:pt-0 sm:pt-10 sm:space-y-5">
      {children}
    </div>
  )
}

type GroupProps = {
  children: React.ReactNode
  description?: string
  label: string
  htmlFor?: string
  intent?: 'primary' | 'danger'
}

const Group = ({ children, label, description, htmlFor, intent = 'primary' }: GroupProps) => {
  const labelStyles = cva('block text-sm font-medium sm:mt-px sm:pt-2', {
    variants: {
      intent: {
        primary: 'text-gray-700',
        danger: 'text-red-500',
      },
    },
  })
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <div>
        <label htmlFor={htmlFor} className={labelStyles({ intent })}>
          {label}
        </label>
        {
          description &&
          <p className="mt-1 text-xs text-gray-500">{description}</p>
        }
      </div>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        {children}
      </div>
    </div>
  )
}

type FooterProps = {
  children: React.ReactNode,
}

const Footer = ({ children }: FooterProps) => {
  return (
    <div className="pt-5 md:pt-12">
      <div className="flex justify-end gap-2">
        {children}
      </div>
    </div>
  )
}

type ReadonlyProps = {
  children: React.ReactNode
}

const ReadOnly = ({ children }: ReadonlyProps) => {
  return (
    <p className="mt-2 text-gray-500">
      {children}
    </p>
  )
}

FormLayout.Title = Title
FormLayout.Block = Block
FormLayout.Group = Group
FormLayout.Footer = Footer
FormLayout.ReadOnly = ReadOnly

export default FormLayout