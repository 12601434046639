import { z } from 'zod'

export type GetTfaRequest = void

export const tfaSchema = z.object({
  otp: z.string().max(24),
})

export type PostVerifyTfaInputs = z.infer<typeof tfaSchema>
export type PostVerifyTfaRequest = PostVerifyTfaInputs

export type PostChallengeTfaInputs = z.infer<typeof tfaSchema>
export type PostChallengeTfaRequest = PostChallengeTfaInputs
