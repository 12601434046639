import { useAdminChallengeTfaMutation } from 'api'
import { PostChallengeTfaInputs } from 'api/types/requests/auth/admin/tfa'
import Card from 'components/cards/default'
import { ChallengeTfaForm } from 'components/forms/tfa/challenge-tfa'
import NotificationToast from 'components/notifications'
import { useNotifier } from 'react-headless-notifier'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { PotentialState } from 'router'
import { login } from 'slices/auth'

export const TfaChallengePage = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { notify } = useNotifier()

  if (!(location.state as PotentialState)?.tfa_challenge) {
    navigate('/admin/login')
  }

  const [challengeTfa, { isLoading, error }] = useAdminChallengeTfaMutation()

  const handleOnSubmit = async (data: PostChallengeTfaInputs) => {
    await challengeTfa(data)
      .unwrap()
      .then((response) => {
        dispatch(login(response.data.user))
        navigate('/admin/dashboard/assets/browse', { state: null })
      })
      .catch((catchError) => {
        if (catchError?.status >= 500) {
          notify(<NotificationToast type='error' message='Failed to verify with two-factor authentication, please try again later' />)
        }
        if (catchError?.status === 429) {
          notify(<NotificationToast type='error' message={catchError?.data?.errors[0].message} />)
        }
      })
  }

  return (
    <div className="h-full lg:p-3 bg-slate-200 p-0">
      <div className="flex max-w-md mx-auto">
        <Card className="gap-y-10">
          <div className="flex flex-col gap-y-1">
            <h3 className="text-lg">
              Two-Factor Authentication
            </h3>
            <p>
              Please enter the six digit code from your authenticator app
            </p>
          </div>

          <ChallengeTfaForm
            onSubmit={(data) => handleOnSubmit(data)}
            isLoading={isLoading}
            errors={error}
          />
        </Card>
      </div>
    </div>
  )
}

export default TfaChallengePage