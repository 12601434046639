export enum AssetType {
  KiCall = 'Kicall',
  Tamo = 'Tamo',
  TamoPro = 'TamoPro',
  TamoMini = 'TamoMini',
  TamoGo = 'TamoGo',
  C500 = 'C500',
  P500 = 'P500',
}

export enum FriendlyAssetType {
  KiCall = 'KiCall',
  Tamo = 'Tamo',
  TamoPro = 'Tamo Pro',
  TamoMini = 'Tamo Mini',
  TamoGo = 'Tamo Go',
  C500 = 'C500',
  P500 = 'P500',
  Unknown = 'Unknown'
}

export function getFriendlyAssetType(product: AssetType | undefined) {
  switch (product) {
    case AssetType.KiCall:
      return FriendlyAssetType.KiCall
    case AssetType.Tamo:
      return FriendlyAssetType.Tamo
    case AssetType.TamoPro:
      return FriendlyAssetType.TamoPro
    case AssetType.TamoMini:
      return FriendlyAssetType.TamoMini
    case AssetType.TamoGo:
      return FriendlyAssetType.TamoGo
    case AssetType.C500:
      return FriendlyAssetType.C500
    case AssetType.P500:
      return FriendlyAssetType.P500
    case undefined:
      return FriendlyAssetType.Unknown
  }
}