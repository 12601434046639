import { Title } from 'components/headings'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link, Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useRegisterMutation } from 'api'
import Button from 'components/button'
import Card from 'components/cards/default'
import Input from 'components/form/input'
import Phone, { PhoneInputProps } from 'components/form/phone'
import { compareArrays } from 'helpers/arrays'
import useErrorHandler from 'hooks/useErrorHandler'
import { updateAuthUser } from 'slices/auth'
import { RegisterState } from 'types/registerState'

type FormInputs = {
  first_name: string,
  last_name: string,
  email: string,
  country_code: string,
  phone: string,
}

const RegisterStepTwo = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const errorHandler = useErrorHandler()
  const state = useLocation().state as RegisterState
  
  const [searchParams] = useSearchParams()

  const requiredFields = ['first_name', 'last_name', 'email', 'phone']

  const { 
    register,
    handleSubmit,
    setValue, 
    setError,
    clearErrors,
    formState: { 
      errors, 
      dirtyFields 
    }, 
  } = useForm<FormInputs>({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      country_code: '',
    },
  })

  const [submitRegister, { isLoading }] = useRegisterMutation()

  const onSubmit = async (data: FormInputs) => {
    const request = {
      ...data,
      masked_uid: searchParams?.get('asset') ?? undefined,
    }

    await submitRegister(request)
      .unwrap()
      .then((response) => {
        // We are not authenticating the user here, but adding the user information to state
        dispatch(updateAuthUser(response.user))
        navigate('/register/step-three', { state: state })
      })
      .catch((error) => {
        errorHandler(error, setError)
      })
  }

  const handlePhoneOnChange = (phone: PhoneInputProps) => {
    setValue('phone', phone.phone, { shouldDirty: true })
    setValue('country_code', phone.country_code)

    if (errors.phone || errors.country_code) {
      clearErrors(['country_code', 'phone'])
    }
  }

  if (state === null){
    return <Navigate to={'/register/step-one'} state={{acceptedTerms: false}} />
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="h-full">
      <Card className="min-h-full">
        <Title>
          Account Details
        </Title>
        <div className="flex flex-col gap-4 mt-8 mb-3">
          <div className="flex gap-3">
            <Input {...register('first_name', { required: true })} placeholder="First Name *" error={errors?.first_name?.message} />
            <Input {...register('last_name', { required: true })} placeholder="Last Name *" error={errors?.last_name?.message} />
          </div>
          <Input {...register('email', { required: true })} type="email" placeholder="Email *" error={errors?.email?.message} />
          <Phone
            onChange={(phone) => handlePhoneOnChange(phone)} 
            error={errors?.phone?.message || errors?.country_code?.message} 
          />
        </div>
        <div className="flex flex-col h-full gap-2 mt-auto">
          <p className="px-1 my-6 text-sm text-center rounded-md text-primary-gray">
            If you already have an account, or abandoned your previous registration, <Link to="/login" className="font-medium transition text-secondary-blue hover:text-secondary-dark-blue">try logging in</Link>
          </p>
          <div className="flex flex-col gap-2 mt-auto">
            <Button type="submit" block disabled={!compareArrays(requiredFields, Object.keys(dirtyFields))} isLoading={isLoading}>
              Continue
            </Button>
            <Button href="/" type="button" block variant="link" className="text-sm">
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </form>
  )
}

export default RegisterStepTwo