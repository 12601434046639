import { PostVerifyTfaInputs, PostVerifyTfaRequest, tfaSchema } from 'api/types/requests/auth/admin/tfa'
import Button from 'components/button'
import { Form } from 'components/form/form'
import Input from 'components/form/input'
import { PropsWithChildren } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { FormBaseProps } from 'types/form'

type VerifyTfaFormProps = FormBaseProps<PostVerifyTfaInputs> & {
  isLoading: boolean
}

export function VerifyTfaForm({
  onSubmit,
  errors,
  isLoading = false,
}: VerifyTfaFormProps) {

  return (
    <Form<PostVerifyTfaRequest, typeof tfaSchema>
      onSubmit={(data) => onSubmit(data)}
      error={errors}
      validationSchema={tfaSchema}
      defaultValues={{
        otp: '',
      }}
    >
      {
        (methods) => (
          <div className="flex flex-col gap-y-10">
            <FormWrapper {...methods} />
            <div className="flex flex-col gap-y-3">
              <Button
                type="submit"
                isLoading={isLoading}
              >
                Continue
              </Button>
              <Link
                to="/admin/login"
                className="text-xs text-center underline underline-offset-2"
              >
                Leave this page if you do not want to continue
              </Link>
            </div>
          </div>
        )
      }
    </Form>
  )
}

type FormWrapperProps = PropsWithChildren<UseFormReturn<PostVerifyTfaInputs>>

function FormWrapper({
  children,
  register,
  formState: { errors },
}: FormWrapperProps) {
  return (
    <>
      <div className="flex flex-col gap-y-4">
        <div>
          <h4 className="font-medium">
            Confirm authenticator code
          </h4>
          <p>
            Enter the six digit code generated by your authenticator app to complete this process
          </p>
        </div>
        <Input {...register('otp')} error={errors?.otp?.message} />
      </div>
      {children}
    </>
  )
}
