import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, EndpointBuilder } from '@reduxjs/toolkit/query'
import Device from '../../types/models/device'
import QueryTags from '../../types/QueryTags'
import AdminDevicesGetRequest from '../../types/requests/device/admin/get'
import AdminDeviceRequest from '../../types/requests/device/admin/store'
import PaginatedResponse from '../../types/requests/PaginatedResponse'
import { stringify } from 'qs'

const adminDeviceEndpoints = (builder: EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>, QueryTags, 'data'
>) => ({
  adminGetDevices: builder.query<PaginatedResponse<Device[]>, AdminDevicesGetRequest>({
    query: ({ page, sort }) => ({
      url: '/admin/devices',
      params: {
        ...(page && { page }),
        ...(sort && { sort: stringify(sort) }),
      },
    }),
    providesTags: ['admin_devices'],
  }),

  adminGetDevice: builder.query<{ device: Device }, string>({
    query: (id) => ({
      url: `/admin/devices/${id}`,
    }),
    providesTags: ['admin_device'],
  }),

  adminStoreDevice: builder.mutation<{ device: Device }, AdminDeviceRequest>({
    query: (body) => ({
      url: '/admin/devices',
      method: 'post',
      body,
    }),
    invalidatesTags: ['admin_asset', 'admin_assets', 'admin_devices', 'admin_device'],
  }),

  adminUpdateDevice: builder.mutation<Device, AdminDeviceRequest>({
    query: ({ id, ...body }) => ({
      url: `/admin/devices/${id}`,
      method: 'put',
      body,
    }),
    invalidatesTags: ['admin_asset', 'admin_assets', 'admin_devices', 'admin_device'],
  }),

  adminDeleteDevice: builder.mutation<void, { id: number }>({
    query: ({ id }) => ({
      url: `/devices/${id}`,
      method: 'delete',
    }),
    invalidatesTags: ['admin_asset', 'admin_assets', 'admin_devices', 'admin_device'],
  }),
})

export default adminDeviceEndpoints