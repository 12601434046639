import { Title } from 'components/headings'
import { useParams, useSearchParams } from 'react-router-dom'
import Button from 'components/button'
import Card from 'components/cards/default'
import AddDeviceModal from 'components/modals/forms/asset_devices/AddDeviceModal'
import RemoveDeviceModal from 'components/modals/forms/asset_devices/DeleteDeviceModal'
import UpdateDeviceModal from 'components/modals/forms/asset_devices/UpdateDeviceModal'
import DeviceCard from 'components/cards/device'
import { useMemo, useState } from 'react'
import Device from 'api/types/models/device'
import { useGetDevicesQuery, useShowAssetQuery } from 'api'
import Spinner from 'components/spinner'
import DetachDeviceModal from 'components/modals/forms/asset_devices/DetachDeviceModal'

enum ManagementTypes {
  MANAGE = 'MANAGE',
  REMOVE = 'REMOVE',
  DETACH = 'DETACH'
}

const AssetAccessPage = () => {
  const params = useParams()

  const [searchParams] = useSearchParams()
  const [addModal, setAddModal] = useState<boolean>(searchParams.has('phone') ?? false)

  const [removeModal, setRemoveModal] = useState(false)
  const [detachModal, setDetachModal] = useState(false)

  const [selectedDevice, setSelectedDevice] = useState<Device>()

  const presetName = searchParams.get('name')
  const presetPhone = searchParams.get('phone')
  const presetCountryCode = searchParams.get('country_code')
  const isRequest = searchParams.get('is_request')

  const { data: assetData, isLoading: getAssetLoading } = useShowAssetQuery({ id: params.uid! })
  const { data: deviceData } = useGetDevicesQuery()

  const orderedDevices = useMemo(() => {
    if (assetData && assetData.asset.devices) {
      const devices = assetData.asset.devices
      const alphabetical = [...devices].sort((a, b) => a.name.localeCompare(b.name))
      return alphabetical.sort((a, b) => Number(b.is_asset_owner) - Number(a.is_asset_owner))
    }

    return []
  }, [assetData])

  const showManagementModal = (device: Device, type: ManagementTypes) => {
    setSelectedDevice(device)

    if (type === ManagementTypes.REMOVE) {
      setRemoveModal(true)
    }

    if (type === ManagementTypes.DETACH) {
      setDetachModal(true)
    }
  }

  if (getAssetLoading && !assetData) {
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <Spinner className="text-primary-gray" />
      </div>
    )
  }

  return (
    <Card>
      <Title>Access</Title>
      <div className="flex flex-col gap-2 mt-6 mb-12">
        {
          orderedDevices.length > 0 ? orderedDevices.map((device, index) => (
            <DeviceCard
              device={device}
              key={index}
              onRemoveClick={() => showManagementModal(device, ManagementTypes.REMOVE)}
              onDetachClick={() => showManagementModal(device, ManagementTypes.DETACH)}
            />
          )) : (
            <p className="text-center">This asset has no registered devices</p>
          )
        }
      </div>
      <div className="flex flex-col gap-2 mt-auto">
        <Button onClick={() => setAddModal(true)} block>Add Person</Button>
        <Button href={`/asset/manage/${params.uid}`} variant="link" className="text-sm" block>Back</Button>
      </div>

      <AddDeviceModal
        devices={deviceData?.devices}
        isOpen={addModal}
        onClose={() => setAddModal(false)}
        name={presetName ?? undefined}
        phone={presetPhone ?? undefined}
        isRequest={!!isRequest}
        countryCode={presetCountryCode ?? undefined}
      />

      <RemoveDeviceModal
        asset={assetData!.asset!}
        device={selectedDevice!}
        isOpen={removeModal}
        onClose={() => setRemoveModal(false)}
      />

      <DetachDeviceModal
        asset={assetData!.asset!}
        device={selectedDevice!}
        isOpen={detachModal}
        onClose={() => setDetachModal(false)}
      />

    </Card>
  )
}

export default AssetAccessPage