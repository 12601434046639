import { Combobox } from '@headlessui/react'
import { useMemo, useState } from 'react'
import classNames from 'classnames'
import SelectorIcon from 'components/icons/SelectorIcon'
import { useDebounce } from 'use-debounce'
import Spinner from 'components/spinner'
import { useFindAddressQuery, useRetrieveAddressQuery } from 'api'
import { SearchResult } from 'api/types/loqate/searchResult'

export type ExpectedAddressDetails = {
  PostalCode: string
  Line1: string
  Line2: string
  City: string
  Province: string
}

type Types = {
  placeHolder?: string
  onSelect: (object: ExpectedAddressDetails) => void
}

const PostCodeLookup = ({ placeHolder, onSelect }: Types) => {
  const [search, setSearch] = useState<string>('')
  const [debouncedSearch] = useDebounce(search, 2000)

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
  const [container, setContainer] = useState<SearchResult | null>(null)
  const [results, setResults] = useState<SearchResult[]>([])
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined)

  const { data: findAddressResult, isLoading: isFindLoading } = useFindAddressQuery(
    { search: debouncedSearch, containerId: container && container.Id },
    { refetchOnMountOrArgChange: true, skip: debouncedSearch.length <= 1 }
  )

  const { data: retrieveAddressResult, isLoading: isRetrieveLoading } = useRetrieveAddressQuery(
    { addressId: selectedId },
    { refetchOnMountOrArgChange: true, skip: !selectedId }
  )

  useMemo(() => {
    if (retrieveAddressResult) {
      onSelect(retrieveAddressResult[0])
      setDropdownOpen(false)
      setSearch('')
    }
  }, [retrieveAddressResult])

  useMemo(() => {
    if (debouncedSearch?.length <= 1) {
      setContainer(null)
      return
    }

    if (findAddressResult) {
      setResults(findAddressResult)
    }
  }, [findAddressResult, container])

  return (
    <div className="w-full">
      <Combobox value={search} onChange={() => { return }}>
        {({ open }) => {
          open = !!search && dropdownOpen

          return (
            <div className="flex w-full gap-1">
              <div className="relative flex w-full">
                <span className="inline-flex items-center px-2 border border-r-0 border-secondary-gray text-primary-gray rounded-l-md bg-gray-50 min-w-max">
                  Search
                </span>
                <div className="relative w-full">

                  <Combobox.Input
                    onKeyUp={() => setDropdownOpen(true)}
                    autoComplete="off"
                    placeholder={placeHolder ?? 'Start typing your address'}
                    className="w-full p-2 leading-6 border rounded-r-md border-secondary-gray text-primary-gray"
                    value={search}
                    onChange={(event) => setSearch(event.currentTarget.value)}
                  />

                  {open && (
                    <Combobox.Options className="absolute w-full overflow-y-scroll bg-white border divide-y shadow-md rounded-b-md max-h-52" static>
                      {
                        results?.map((result) => (
                          <Combobox.Option
                            key={result.Id}
                            value={search}
                            className={({ active }) => classNames('px-2 py-1 text-xs cursor-pointer hover:bg-primary hover:text-white', active && 'bg-primary text-white')}
                            onClick={() => {
                              if (result.Type === 'Address') {
                                return setSelectedId(result.Id)
                              }
                              setContainer(result)
                            }}
                          >
                            {result.Text && `${result.Text},`} {result.Description}
                          </Combobox.Option>
                        ))
                      }
                    </Combobox.Options>
                  )}
                </div>

                {
                  (search?.length > 0 && search !== debouncedSearch) &&
                  <div className="absolute right-0 flex h-full pr-2">
                    <Spinner className='my-auto text-sm text-primary-gray' />
                  </div>
                }

              </div>
              <Combobox.Button className="p-1 bg-white border rounded-md hover:bg-gray-100 text-primary-gray border-custom-gray" onClick={() => { setDropdownOpen(dropdownOpen ? false : true) }}>
                <SelectorIcon classNames="w-4 h-4" />
              </Combobox.Button>
            </div>
          )
        }}
      </Combobox>
    </div>
  )
}



export default PostCodeLookup
