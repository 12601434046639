import dataApi from 'api'
import { Client } from 'api/types/models/client'
import PaginatedResponse from 'api/types/requests/PaginatedResponse'
import CreateClientRequest from 'api/types/requests/admin/client/store'
import UpdateClientRequest from 'api/types/requests/client/update'

const clientBaseUrl = '/admin/clients'

const clientEndpoints = dataApi.injectEndpoints({
  endpoints: (build) => ({
    createClient: build.mutation<Client, CreateClientRequest>({
      query: (body) => ({
        url: clientBaseUrl,
        method: 'post',
        body,
      }),
      invalidatesTags: ['clients'],
    }),

    getClients: build.query<PaginatedResponse<Client[]> | Client[], { page?: number }>({
      query: (params) => ({
        url: clientBaseUrl,
        params,
      }),
      providesTags: ['clients'],
    }),

    getClient: build.query<Client, number>({
      query: (id) => ({
        url: `${clientBaseUrl}/${id}`,
      }),
      providesTags: ['client'],
    }),

    updateClient: build.mutation<Client, UpdateClientRequest>({
      query: ({ id, ...body }) => ({
        url: `${clientBaseUrl}/${id}`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        // Update the cache'd client with the new data
        try {
          const { data: updatedClient } = await queryFulfilled
          dispatch(
            clientEndpoints.util.updateQueryData('getClient', id, (draft) => {
              Object.assign(draft as Record<string, unknown>, updatedClient)
            }),
          )
        } catch {
          //
        }
      },
      invalidatesTags: ['clients'],
    }),

    // download a CSV copy of the client
    exportClient: build.mutation<boolean, Client>({
      queryFn: async ({ id }, _api, _extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `${clientBaseUrl}/${id}/export`,
          responseHandler: ((response) => response.blob()),
        })
        
        const hiddenElement = document.createElement('a')
        const url = window.URL || window.webkitURL
        const blobCSV = url.createObjectURL(result.data as Blob)
        
        hiddenElement.href = blobCSV
        hiddenElement.target = '_blank'
        hiddenElement.download = `client_report_${id}.csv`
        hiddenElement.click()
        
        return { data: true }
      },
      invalidatesTags: ['clients'],
    }),

    deleteClient: build.mutation<void, number>({
      query: (id) => ({
        url: `${clientBaseUrl}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['clients'],
    }),
  }),
})

export const {
  useCreateClientMutation,
  useGetClientsQuery,
  useGetClientQuery,
  useUpdateClientMutation,
  useExportClientMutation,
  useDeleteClientMutation,
} = clientEndpoints