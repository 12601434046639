import { HorizontalDivider } from 'components/Dividers/horizontal'
import { FormControl } from 'components/form/control'
import { Form } from 'components/form/form'
import Input from 'components/form/input'
import { FormLabel } from 'components/form/label'
import { Title } from 'components/headings'
import { WeekDays } from 'components/week-days'
import { WeekDay } from 'enums/WeekDay'
import ReactQuill from 'react-quill'
import { z } from 'zod'
import { DateTime } from 'luxon'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { SerializedError } from '@reduxjs/toolkit'
import FormError from 'components/error'
import Toggle from 'components/form/toggle'
import { useParams } from 'react-router-dom'
import { UseFormReturn } from 'react-hook-form'
import { useGetMessagesQuery } from 'api/endpoints/user/message'
import { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { ConfirmationModal } from 'components/modals/confirmation'
import { MessageConflict } from 'feature/message/components/lists/conflict'
import { timePeriodSchema } from 'helpers/validation/fields/time'
import { FormBaseProps } from 'types/form'
import Button from 'components/button'

const baseMessageSchema = z.object({
  is_default: z.boolean(),
  name: z.string().min(1, 'Name is required'),
  content: z.string().min(1, 'Content is required'),
})

const defaultMessageSchema = z.object({
  is_default: z.literal(true),
})

const timeFrameSchema = z.object({
  is_default: z.literal(false),
  days_of_week: z.array(z.nativeEnum(WeekDay)).min(1, 'At least one day of the week is required'),
}).and(timePeriodSchema)

const messageSchema = baseMessageSchema.and(z.union([timeFrameSchema, defaultMessageSchema]))

export type MessageInputs = z.infer<typeof messageSchema> & {
  asset_id: number | undefined,
}

type MessageFormProps = FormBaseProps<MessageInputs> & {
  btnTxt: string,
  isLoading: boolean,
}

function MessageFormWrapper({
  btnTxt,
  isLoading,
  getValues,
  setValue,
  control,
  trigger,
  watch,
  register,
  formState: { errors, defaultValues },
}: PropsWithChildren<UseFormReturn<MessageInputs> & { btnTxt: string, isLoading: boolean }>) {
  const { messageId, uid } = useParams()

  const [openConfirmDefaultModal, setOpenConfirmDefaultModal] = useState({
    open: false,
    value: getValues('is_default'),
  })

  const { data: existingMessages } = useGetMessagesQuery({
    asset_identifier: uid!,
  }, { skip: !uid })

  const handleDefaultSwitch = () => {
    setValue('is_default', openConfirmDefaultModal.value)
    setValue('days_of_week', [])
    setValue('start_time', '')
    setValue('end_time', '')
    setOpenConfirmDefaultModal({ open: false, value: false })
  }

  const conflictingTimeFrames = useMemo(() => {
    const messageTimeFrames = getValues('days_of_week')?.map(day => ({
      day_of_week: day,
      start_time: getValues('start_time'),
      end_time: getValues('end_time'),
    }))

    const existingTimeFrames = existingMessages
      ?.reduce((acc, message) => {
        return [
          ...acc,
          ...message.time_frames?.map(tf => ({
            messageId: message.id,
            name: message?.name ?? '',
            start_time: tf.start_time as string,
            end_time: tf.end_time as string,
            day_of_week: tf.day_of_week,
          })) ?? [],
        ]
      }, [] as { messageId: number, name: string, day_of_week: WeekDay, start_time: string, end_time: string }[])
      ?.filter(timeFrame => {
        if (timeFrame?.messageId === parseInt(messageId ?? '0')) return

        return messageTimeFrames?.some(messageTimeFrame => {
          return timeFrame.day_of_week === messageTimeFrame.day_of_week &&
            DateTime.fromISO(timeFrame.start_time as string) <= DateTime.fromISO(messageTimeFrame.end_time as string) &&
            DateTime.fromISO(timeFrame.end_time as string) >= DateTime.fromISO(messageTimeFrame.start_time as string)
        })
      })

    return existingTimeFrames
  }, [existingMessages, watch(['days_of_week', 'start_time', 'end_time'])])

  const toolbarOptions = [
    [{ 'header': [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'link'],
    [{ align: '' }, { align: 'center' }, { align: 'right' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }, { 'list': 'ordered' }, { 'list': 'bullet' }],
    ['clean'],
  ]

  /*
    RHF uses field-level validation to handle dependant fields,
    so need to use trigger API to manually trigger validation
  */
  useEffect(() => {
    trigger('end_time')
  }, [watch('start_time')])

  useEffect(() => {
    trigger('start_time')
  }, [watch('end_time')])

  return (
    <div className="space-y-4">
      <FormControl>
        <FormLabel htmlFor="name">Name</FormLabel>
        <Input
          id="name"
          {...register('name', { required: true })}
          placeholder="Enter a name for this message"
          error={errors?.name?.message}
        />
      </FormControl>

      <HorizontalDivider />

      <div>
        <Title className="!text-left !mb-0" size="lg">Displayed Message</Title>
        <p className="text-sm text-gray-500">Write and style the message that is seen when scanned</p>
      </div>

      <FormControl>
        <ReactQuill
          theme="snow"
          value={getValues('content')}
          placeholder="Write a message and use the toolbar above to style it"
          modules={{ toolbar: toolbarOptions }}
          onChange={(html) => {
            trigger('content')
            setValue('content', html, {
              shouldDirty: getValues('content') !== defaultValues?.content,
            })
          }}
        />
        {
          errors.content?.message &&
          <FormError text={errors.content.message} />
        }
      </FormControl>

      <HorizontalDivider />

      <div>
        <Title className="!text-left !mb-0" size="lg">Default Message</Title>
        {
          watch('is_default') &&
          <div className="px-3 py-2 my-4 border rounded border-primary bg-primary bg-opacity-5 text-primary">
            <p className="text-sm">
              To configure a time frame for this message, you will need to set another message as the default.
            </p>
          </div>
        }
        <div className="flex items-center justify-between w-full gap-3">
          <p className="text-sm text-gray-500">Displayed outside configured time frames for this asset</p>
          <Toggle
            disabled={watch('is_default')}
            checked={watch('is_default')}
            onChange={(value) => setOpenConfirmDefaultModal({ open: true, value })}
          />
        </div>
      </div>

      {
        !watch('is_default') && (
          <>
            <HorizontalDivider />

            <div>
              <Title className="!text-left !mb-0" size="lg">Time Frame</Title>
              <p className="text-sm text-gray-500">Configure when this message is displayed</p>
            </div>

            <span className="block my-8 font-bold">Repeats</span>

            <FormControl>
              <WeekDays name="days_of_week" control={control} />
              {
                'days_of_week' in errors && errors?.days_of_week?.message &&
                <FormError text={errors?.days_of_week?.message} />
              }
            </FormControl>


            <span className="block my-8 font-bold">Between</span>

            <div>
              <div className="flex items-center gap-5">
                <FormControl>
                  <Input
                    type="time"
                    {...register('start_time')}
                  />
                </FormControl>

                <span>to</span>

                <FormControl className="mb-auto">
                  <Input
                    type="time"
                    {...register('end_time')}
                  />
                </FormControl>
              </div>

              <div className="grid grid-cols-2 gap-14">
                {
                  'start_time' in errors && errors?.start_time?.message &&
                  <FormError text={errors?.start_time?.message} />
                }
                {
                  'end_time' in errors && errors?.end_time?.message &&
                  <FormError text={errors?.end_time?.message} />
                }
              </div>
            </div>
          </>
        )
      }

      <MessageConflict value={conflictingTimeFrames ?? []} />

      <Button
        block
        type="submit"
        className="!mt-16"
        disabled={(conflictingTimeFrames?.length ?? 0) > 0}
        isLoading={isLoading}
      >
        {btnTxt}
      </Button>

      <ConfirmationModal
        title="Confirm Default Message"
        isOpen={openConfirmDefaultModal.open}
        onClose={() => setOpenConfirmDefaultModal({ open: false, value: false })}
        renderContent={() => (
          <div>
            <p>You&apos;re switching this scheduled message to the default message.</p>
            <br />
            <p>Your existing default message will be changed to a scheduled message and will need to be configured. This message will display outside of scheduled messages if confirmed.</p>
          </div>
        )}
        onConfirm={handleDefaultSwitch}
      />
    </div>
  )
}

export function MessageForm({
  onSubmit,
  errors,
  isLoading,
  btnTxt,
  defaultValues,
}: MessageFormProps) {
  return (
    <Form<MessageInputs, typeof messageSchema>
      className="pt-4"
      onSubmit={onSubmit}
      error={errors}
      validationSchema={messageSchema}
      defaultValues={defaultValues}
    >
      {(methods) =>
        <MessageFormWrapper
          btnTxt={btnTxt}
          isLoading={isLoading}
          {...methods}
        />
      }
    </Form>
  )
}