import { useNotifier } from 'react-headless-notifier'
import NotificationToast from '../components/notifications'

const useErrorHandler = () => {
  const { notify } = useNotifier()

  return (error: any, setError: (field: any, { message }: { message: string }) => void) => {
    if (error?.data?.errors && setError) {
      const fields: string[] = []

      error.data.errors.forEach((error: { field: string, message: string }) => {
        if (!fields.includes(error.field)) {
          setError(error.field, {message: error.message})
          fields.push(error.field)
        }
      })
    } else if (error?.data?.message) {
      notify(
        <NotificationToast
          type="error"
          message={error?.data?.message}
        />,
      )
    } else {
      notify(
        <NotificationToast
          type="error"
          message="Something went wrong, try again soon."
        />
      )
    }
  }
}

export default useErrorHandler
