import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, EndpointBuilder } from '@reduxjs/toolkit/query'
import QueryTags from 'api/types/QueryTags'
import { GetProductsResponse } from 'api/types/requests/product/get'

const endpointBase = '/admin/products'

const productEndpoints = (builder: EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>, QueryTags, 'data'
>) => ({
  getProducts: builder.query<GetProductsResponse, void>({
    query: () => endpointBase,
  }),
})

export default productEndpoints