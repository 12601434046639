import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, EndpointBuilder } from '@reduxjs/toolkit/query'
import QueryTags from '../../types/QueryTags'
import { serialize } from 'object-to-formdata'
import KiCallFile from 'api/types/models/file'
import { AdminFileStoreRequest } from 'api/types/requests/admin/file/store'
import PaginatedResponse from 'api/types/requests/PaginatedResponse'
import AdminFileGetRequest from 'api/types/requests/admin/file/get'
import { stringify } from 'qs'
import { User } from 'api/types/models/user'
import { AdminAssetFileUpdateRequest, AdminFileUpdateRequest } from 'api/types/requests/admin/file/update'

const adminFileEndpoints = (builder: EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>, QueryTags, 'data'
>) => ({
  adminGetFiles: builder.query<PaginatedResponse<User[]>, AdminFileGetRequest>({
    query: ({ page, sort, search }) => ({
      url: '/admin/files',
      params: {
        ...(page && { page }),
        ...(sort && { sort: stringify(sort) }),
        ...(search && { search }),
      },
    }),
    providesTags: ['admin_files'],
  }),

  adminGetUserFiles: builder.query<{ user: User }, string>({
    query: (userId) => ({
      url: `/admin/files/user/${userId}`,
    }),
  }),

  adminGetAssetFiles: builder.query<{ available_files: KiCallFile[] }, string | undefined>({
    query: (assetId) => ({
      url: `/admin/files/asset/${assetId}`,
    }),
  }),

  adminUpdateAssetFiles: builder.mutation<{ files: KiCallFile[] }, { assetId: string, body: AdminAssetFileUpdateRequest }>({
    query: ({ assetId, body }) => ({
      url: `/admin/files/asset/${assetId}`,
      method: 'put',
      body: body,
    }),
  }),

  adminStoreFiles: builder.mutation<{ files: KiCallFile[] }, AdminFileStoreRequest>({
    query: (body) => ({
      url: '/admin/files',
      method: 'post',
      body: (body && serialize({
        user_id: body.user_id,
        attachments: body.attachments && body.attachments.map((attachment) => attachment.file),
        filenames: body.filenames && body.filenames.map((filename) => filename.name),
      })),
    }),
  }),

  adminUpdateFiles: builder.mutation<{ user: User }, { userId: string, body: AdminFileUpdateRequest }>({
    query: ({ userId, body }) => ({
      url: `/admin/files/${userId}`,
      method: 'put',
      body: (body && serialize({
        filenames: body.filenames && body.filenames.map((filename) => filename.name),
        attachments: body.attachments && body.attachments.map((attachment) => attachment.file),
        current_attachments: body.current_attachments && body.current_attachments.map((attachment) => ({ id: attachment?.id, deleted: attachment?.deleted, name: attachment?.name })),
      }, { dotsForObjectNotation: true, indices: true })),
    }),
  }),

  adminDeleteUserFiles: builder.mutation<void, { userId: number }>({
    query: ({ userId }) => ({
      url: `/admin/files/${userId}`,
      method: 'delete',
    }),
    invalidatesTags: ['admin_files'],
  }),
})

export default adminFileEndpoints