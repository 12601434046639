import { BaseQueryFn, EndpointBuilder, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query'
import Device from '../../types/models/device'
import QueryTags from '../../types/QueryTags'
import DeviceStoreRequest from '../../types/requests/device/store'
import DeviceUpdateRequest from '../../types/requests/device/update'
import DeviceAttachRequest from 'api/types/requests/device/attach'
import DeviceDetachRequest from 'api/types/requests/device/detach'

const deviceEndpoints = (builder: EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>, QueryTags, 'data'
>) => ({
  getDevices: builder.query<{ devices: Device[] }, void>({
    query: () => ({
      url: '/devices',
    }),
    providesTags: ['devices'],
  }),

  getDevice: builder.query<Device, { id: string }>({
    query: ({ id }) => ({
      url: `/devices/${id}`,
    }),
    providesTags: ['devices', 'assets'],
  }),

  attachDevice: builder.mutation<void, DeviceAttachRequest>({
    query: (body) => ({
      url: '/devices/attach',
      method: 'post',
      body,
    }),
    invalidatesTags: ['asset'],
  }),

  detachDevice: builder.mutation<void, DeviceDetachRequest>({
    query: ({ id, body }) => ({
      url: `/devices/detach/${id}`,
      method: 'put',
      body,
    }),
    invalidatesTags: ['asset'],
  }),

  storeDevice: builder.mutation<{ device: Device }, DeviceStoreRequest>({
    query: (body) => ({
      url: '/devices',
      method: 'post',
      body,
    }),
    invalidatesTags: ['asset', 'devices'],
  }),

  updateDevice: builder.mutation<{ device: Device }, DeviceUpdateRequest>({
    query: ({ id, body }) => ({
      url: `/devices/${id}`,
      method: 'put',
      body,
    }),
    invalidatesTags: ['asset', 'devices'],
  }),

  deleteDevice: builder.mutation<void, number>({
    query: (id) => ({
      url: `/devices/${id}`,
      method: 'delete',
    }),
    invalidatesTags: ['asset', 'devices'],
  }),
})

export default deviceEndpoints