import FormHeader from 'components/admin/form_header'
import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAdminStoreDeviceMutation } from 'api'
import AdminDeviceForm from 'components/admin/forms/device'
import { useToast } from 'hooks/useToast'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

const AdminDeviceCreatePage = () => {
  const navigate = useNavigate()
  const search = useLocation().search

  const { successToast, errorToast } = useToast()

  const assetIdUrl = useMemo(() => {
    const assetId = (new URLSearchParams(search).get('asset'))
    return assetId ? `/admin/dashboard/assets/manage/${assetId}` : null
  }, [search])

  const [createDevice, {
    error: createDeviceError,
    isLoading: isCreatingDevice,
  }] = useAdminStoreDeviceMutation()

  const handleSubmit = async (data: any) => {
    await createDevice(data)
      .unwrap()
      .then(() => {
        successToast({
          title: 'Person Created',
          message: 'Person has been created successfully',
        })

        navigate(assetIdUrl ? assetIdUrl : '/admin/dashboard/devices/browse')
      })
      .catch((err) => {
        if (err?.status === 422 || err?.data?.errors) return

        errorToast({
          title: 'Person Creation Failed',
          message: 'Unable to create the person, please try again later.',
        })
      })
  }

  const fetchError = (createDeviceError as FetchBaseQueryError)

  return (
    <div className="flex flex-col h-full">
      <FormHeader
        title="Create Person"
        description="Fill in the fields below to create a new Person"
        backBtnText="Back to People"
        backBtnUrl="/admin/dashboard/devices/browse"
      />

      <AdminDeviceForm
        onSubmit={handleSubmit}
        onSubmitError={(fetchError?.status === 422 || (fetchError?.data as { errors: [] })?.errors) ? createDeviceError : undefined}
        onSubmitLoading={isCreatingDevice}
      />
    </div>
  )
}

export default AdminDeviceCreatePage