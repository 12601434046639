import { PostChallengeTfaInputs, PostChallengeTfaRequest, tfaSchema } from 'api/types/requests/auth/admin/tfa'
import Button from 'components/button'
import { Form } from 'components/form/form'
import Input from 'components/form/input'
import { FormBaseProps } from 'types/form'

type ChallengeTfaFormProps = FormBaseProps<PostChallengeTfaInputs> & {
  isLoading: boolean
}

export function ChallengeTfaForm({
  onSubmit,
  errors,
  isLoading = false,
}: ChallengeTfaFormProps) {

  return (
    <Form<PostChallengeTfaRequest, typeof tfaSchema>
      onSubmit={(data) => onSubmit(data)}
      error={errors}
      validationSchema={tfaSchema}
      defaultValues={{
        otp: '',
      }}
    >
      {
        ({ register, formState: { errors: formErrors } }) => (
          <div className="flex flex-col gap-y-3">
            <div className="flex flex-col gap-y-10">
              <Input
                {...register('otp')}
                error={formErrors?.otp?.message}
              />
              <Button
                type="submit"
                isLoading={isLoading}
              >
                Submit Code
              </Button>
            </div>
            <p className="text-xs text-gray-500 text-center">
              Alternatively you can use recovery codes to access your account
            </p>
          </div>
        )
      }
    </Form>
  )
}
