import QRCode, { QRCodeProps } from 'react-qr-code'

export function QrCode ({ value, size = 100, bgColor, fgColor, level }: QRCodeProps) {
  return (
    <QRCode
      value={value}
      size={size}
      bgColor={bgColor}
      fgColor={fgColor}
      level={level}
    />
  )
}
