import { HorizontalDivider } from 'components/Dividers/horizontal'
import Button from 'components/button'
import { FormControl } from 'components/form/control'
import { Form } from 'components/form/form'
import { FormLabel } from 'components/form/label'
import { Title } from 'components/headings'
import { z } from 'zod'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { FormBaseProps } from 'types/form'
import Asset from 'api/types/models/asset'
import { UseFormReturn } from 'react-hook-form'
import { ConfirmationModal } from 'components/modals/confirmation'
import { useDeregisterAssetMutation } from 'api'
import { useToast } from 'hooks/useToast'

const assetDetailSchema = z.object({})

export type AssetSecurityFormInputs = z.infer<typeof assetDetailSchema>

type AssetSecurityFormProps = FormBaseProps<AssetSecurityFormInputs> & {
  asset: Asset | undefined
  onDirty: (isDirty: boolean) => void
}

function FormWrapper({
  asset,
}: UseFormReturn<AssetSecurityFormInputs> & { asset: Asset | undefined, onDirty: (isDirty: boolean) => void }) {

  const [openDeregistrationModal, setOpenDeregistrationModal] = useState(false)
  const navigate = useNavigate()
  const { errorToast, successToast } = useToast()

  const [deregisterAsset, {
    isLoading: isDeregisterLoading,
  }] = useDeregisterAssetMutation()

  const handleDeregistration = async () => {
    await deregisterAsset({ assetUid: asset!.masked_uid })
      .unwrap()
      .then(() => {
        navigate('/asset/manage/list')
        successToast({
          title: 'Deregistering Asset',
          message: 'Successfully deregistered your KiCall',
        })
      })
      .catch(() => errorToast({
        title: 'Deregistering Asset',
        message: 'There was a problem deregistering your KiCall',
      }))
  }

  return (
    <>
      <div className="flex flex-col gap-2">
        <div>
          <Title className='!text-left' size='lg'>De-register KiCall</Title>
          <p className="text-sm text-gray-500">Remove your information, and all related details, from this KiCall.</p>
        </div>

        <FormControl>
          <Button variant='danger' onClick={()=>setOpenDeregistrationModal(true)}>Deregister</Button>
        </FormControl>
      </div>
      <ConfirmationModal
        title="Deregister KiCall"
        btnTxt="Deregister"
        isOpen={openDeregistrationModal}
        onClose={() => setOpenDeregistrationModal(false)}
        onConfirm={handleDeregistration}
        isLoading={isDeregisterLoading}
        renderContent={() => (
          <>
            <p>You&apos;re about to deregister this KiCall. By performing this action, all associated data will be removed.</p>
            <br />
            <p>Are you sure you want to continue?</p>
          </>
        )}
      />
    </>
  )
}

export function AssetSecurityForm({
  asset,
  onSubmit,
  errors,
  onDirty,
  defaultValues,
}: AssetSecurityFormProps) {
  return (
    <>
      <Form<AssetSecurityFormInputs, typeof assetDetailSchema>
        onSubmit={onSubmit}
        validationSchema={assetDetailSchema}
        error={errors}
        defaultValues={defaultValues}
      >
        {(methods) => (
          <FormWrapper
            onDirty={onDirty}
            asset={asset}
            {...methods}
          />
        )}
      </Form>
    </>

  )
}