import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useAdminShowUserQuery, useAdminUpdateUserMutation } from 'api'
import AdminUserForm, { UserFormInputs } from 'components/admin/forms/user'
import Spinner from 'components/spinner'
import FormHeader from 'components/admin/form_header'
import { HorizontalDivider } from 'components/Dividers/horizontal'
import FormLayout from 'components/admin/forms/layouts/FormLayout'
import Button from 'components/button'
import { RootState } from 'store'
import { UserRole } from 'enums/UserRole'
import { AccessRequestLayout } from 'layouts/AccessRequestLayout'
import { useToast } from 'hooks/useToast'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

const AdminUserManagePage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { successToast, errorToast } = useToast()

  const authUser = useSelector((state: RootState) => state.auth.user)

  const {
    data: user,
    error: showError,
    isLoading: isLoadingUser,
  } = useAdminShowUserQuery({ id: parseInt(id!) }, {
    skip: !id,
  })

  const [updateUser, {
    error: updateUserError,
    isLoading: isUpdatingUser,
  }] = useAdminUpdateUserMutation()

  const handleSubmit = async (inputs: UserFormInputs) => {
    await updateUser({
      id: parseInt(id!, 10),
      client_id: inputs.client_id,
      first_name: inputs.first_name,
      last_name: inputs.last_name,
      email: inputs.email,
      phone: inputs.phone,
      country_code: inputs.country_code,
      street_address: inputs.street_address,
      second_line: inputs.second_line,
      city: inputs.city,
      county: inputs.county,
      country: inputs.country,
      postcode: inputs.postcode,
    }).unwrap()
      .then(() => {
        successToast({
          title: 'User Updated',
          message: 'User has been updated successfully.',
        })
      })
      .catch((err) => {
        if (err?.status === 422) return

        errorToast({
          title: 'Failed to Update User',
          message: 'Unable to update user, please try again later.',
        })
      })
  }

  useEffect(() => {
    if (showError) {
      navigate('/admin/dashboard/users/browse')
      errorToast({
        title: 'Failed to Retrieve User',
        message: 'Unable to retrieve user, please try again later.',
      })
    }
  }, [showError])

  if (!user || isLoadingUser) {
    return (
      <div className="flex justify-center p-3">
        <Spinner className="text-primary-gray" />
      </div>
    )
  }

  const fetchError = (updateUserError as FetchBaseQueryError)

  return (
    <AccessRequestLayout
      userId={user?.id}
      blockRedirect={!user?.id || authUser?.id === user?.id}
      redirectUrl="/admin/dashboard/users/browse"
    >
      <div className="flex flex-col h-full">
        <FormHeader
          title="Manage User"
          description="Manage User information"
          backBtnText="Back to Users"
          backBtnUrl="/admin/dashboard/users/browse"
        />

        <AdminUserForm
          user={user}
          type="update"
          btnTxt="Update User"
          onSubmit={handleSubmit}
          isLoading={isUpdatingUser}
          defaultValues={{
            client_id: user?.client_id as number ?? null,
            first_name: user?.first_name ?? '',
            last_name: user?.last_name ?? '',
            email: user?.email ?? '',
            country_code: user?.country_code ?? '44',
            phone: user?.phone ?? '',
            street_address: user?.street_address ?? '',
            second_line: user?.second_line ?? '',
            city: user?.city ?? '',
            county: user?.county ?? '',
            country: user?.country ?? '',
            postcode: user?.postcode ?? '',
          }}
          errors={fetchError?.status === 422 ? fetchError : undefined}
        />

        <HorizontalDivider />

        {
          user?.role === UserRole.OWNER &&
          <FormLayout>
            <FormLayout.Block>
              <div className="flex items-center justify-between gap-5">
                <FormLayout.Title
                  title="Credit Usage"
                  subtitle="View the credit usage for this users assets"
                />
                <Button
                  href={`/admin/dashboard/credit-logs?users=${user?.id}`}
                >
                  View Credit Usage
                </Button>
              </div>
            </FormLayout.Block>
          </FormLayout>
        }
      </div>
    </AccessRequestLayout>

  )
}

export default AdminUserManagePage