import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import useErrorHandler from 'hooks/useErrorHandler'
import FormLayout from '../layouts/FormLayout'
import Input from 'components/form/input'
import Button from 'components/button'
import Theme from 'api/types/models/theme'
import UploadIcon from 'components/icons/UploadIcon'
import FormError from 'components/error'
import ComboBox from 'components/form/combobox'
import { useGetClientsQuery } from 'api/endpoints/admin/client'
import { Client } from 'api/types/models/client'

type FormInputs = {
  client_id?: number | null
  title: string
  description: string
  background: File
  logo: File
}

type FormProps = {
  theme?: Theme
  onSubmitError?: FetchBaseQueryError | SerializedError | undefined
  onSubmit: (data: FormInputs) => void
  onSubmitBtnText?: string
  onSubmitLoading?: boolean
}

type PreviewUrls = {
  background?: string
  logo?: string
}

const AdminThemeForm = ({ 
  theme, 
  onSubmitError, 
  onSubmit, 
  onSubmitBtnText = 'Create', 
  onSubmitLoading = false, 
}: FormProps) => {
  const errorHandler = useErrorHandler()

  const {
    data: clients,
    isLoading: isClientsLoading,
  } = useGetClientsQuery({})

  const [previewUrls, setPreviewUrls] = useState<PreviewUrls>({
    background: undefined,
    logo: undefined,
  })

  const backgroundRef = useRef<any>(null)
  const logoRef = useRef<any>(null)

  /**
   * Not setting any default value for images here if the user
   * wants to submit a new image they just have to add it in the form.
   */
  const { 
    register, 
    handleSubmit, 
    setError,
    setValue,
    formState: { errors }, 
    control, 
  } = useForm<FormInputs>({
    defaultValues: {
      client_id: theme?.client_id,
      title: theme?.title,
      description: theme?.description,
    },
  })

  const submitWrapper = (data: FormInputs) => {
    return onSubmit(data)
  }

  useEffect(() => {
    if (onSubmitError) {
      errorHandler(onSubmitError, setError)
    }
  }, [onSubmitError])

  useEffect(() => {
    if (theme) {
      setPreviewUrls({ logo: theme.logo.url, background: theme.background.url })
    }
  }, [theme])

  const clientOptions = useMemo(() => {
    const unpaginatedClients = clients as Client[]
    return unpaginatedClients?.map((client) => ({ value: client.id, text: client.display_name }))
  }, [clients])

  return (
    <FormLayout>
      <form onSubmit={handleSubmit(submitWrapper)}>
        <FormLayout.Block>
          <FormLayout.Title
            title="Asset Theme"
            subtitle="Asset's theme, including background and logo"
          />

          <FormLayout.Group
            label="Client"
            htmlFor="client"
            description="Assign the client this theme will belong to"
          >
            <ComboBox
              isLoading={isClientsLoading}
              onChange={(option) => {
                if (option) setValue('client_id', option.value as number)
              }}
              options={clientOptions}
              defaultValue={theme?.client_id as number}
              placeholder="Search for a client"
            />
            {
              errors.client_id?.message &&
              <FormError text={errors?.client_id?.message} className="mt-1 mb-2 !text-left" />
            }
          </FormLayout.Group>

          <FormLayout.Group
            label="Title"
            htmlFor="title"
          >
            <Input {...register('title')} error={errors.title?.message} />
          </FormLayout.Group>

          <FormLayout.Group
            label="Description"
            htmlFor="description"
          >
            <Input {...register('description')} error={errors.description?.message} />
          </FormLayout.Group>

          <FormLayout.Group
            label="Background Upload"
            description="The background image behind the KiCall message (recommended dimensions 1920x1080). This image can be no larger than 2MB, and must be one of these types: jpg, jpeg, png or gif."
            htmlFor="background"
          >
            <Controller
              name="background"
              control={control}
              render={({ field }) => {
                const handleChange = async (files: File[] | FileList | null) => {
                  if (files) {
                    const preview = URL.createObjectURL(files[0])
                    if (setPreviewUrls) setPreviewUrls({ ...previewUrls, background: preview })

                    field.onChange(files[0])
                  } else {
                    field.onChange(null)
                  }
                }

                return (
                  <>
                    {
                      previewUrls.background
                        ? (
                          <div className="h-36">
                            <button type="button" onClick={() => ( backgroundRef.current?.click() )} className="relative rounded-lg focus:outline-primary group">
                              <img src={previewUrls.background} alt="background" className="object-cover h-32 overflow-hidden border rounded-lg border-secondary-gray aspect-video" />
                              <div className="transition-opacity absolute p-2 bg-white rounded-full opacity-0 group-hover:opacity-80 group-focus:opacity-80 top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 border">
                                <UploadIcon className="w-4 aspect-square" />
                              </div>
                            </button>
                          </div>
                        ) : (
                          <div className="w-full h-36">
                            <button type="button" onClick={() => ( backgroundRef.current?.click() )} className="w-full h-32 border-2 border-dashed rounded-lg border-secondary-gray focus:border-primary group focus:outline-none">
                              <div className="w-4 h-4 mx-auto text-gray group-focus:text-primary">
                                <UploadIcon />
                              </div>
                            </button>
                          </div>
                        )
                    }

                    {
                      errors.background &&
                      <FormError className="max-w-max" text={errors.background.message} />
                    }

                    <input
                      type="file"
                      accept="image/jpeg, image/jpg, image/png"
                      className="hidden"
                      ref={backgroundRef}
                      onChange={(input) => handleChange(input.target?.files)}
                    />
                  </>
                )
              }}
            />
          </FormLayout.Group>

          <FormLayout.Group
            label="Logo Upload"
            description="The header image above the KiCall message (recommended dimensions 350x200). This image can be no larger than 2MB, and must be a png."
            htmlFor="background"
          >
            <Controller
              name="logo"
              control={control}
              render={({ field }) => {
                const handleChange = async (files: File[] | FileList | null) => {
                  if (files) {
                    const preview = URL.createObjectURL(files[0])
                    if (setPreviewUrls) setPreviewUrls({ ...previewUrls, logo: preview })

                    field.onChange(files[0])
                  } else {
                    field.onChange(null)
                  }
                }

                return (
                  <>
                    {
                      previewUrls.logo
                        ? (
                          <div className="h-36">
                            <button type="button" onClick={() => ( logoRef.current?.click() )} className="relative rounded-lg focus:outline-primary group">
                              <img src={previewUrls.logo} alt="logo" className="object-contain h-32 overflow-hidden border rounded-lg border-secondary-gray aspect-video" />
                              <div className="transition-opacity absolute p-2 bg-white rounded-full opacity-0 group-hover:opacity-80 group-focus:opacity-80 top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 border">
                                <UploadIcon className="w-4 aspect-square" />
                              </div>
                            </button>
                          </div>
                        ) : (
                          <div className="w-full h-36">
                            <button type="button" onClick={() => ( logoRef.current?.click() )} className="w-full h-32 border-2 border-dashed rounded-lg border-secondary-gray focus:border-primary group focus:outline-none">
                              <div className="w-4 h-4 mx-auto text-gray group-focus:text-primary">
                                <UploadIcon />
                              </div>
                            </button>
                          </div>
                        )
                    }

                    {
                      errors.logo &&
                      <FormError className="max-w-max" text={errors.logo.message} />
                    }

                    <input
                      type="file"
                      accept="image/jpeg, image/jpg, image/png"
                      className="hidden"
                      ref={logoRef}
                      onChange={(input) => handleChange(input.target?.files)}
                    />
                  </>
                )
              }}
            />
          </FormLayout.Group>

        </FormLayout.Block>

        <FormLayout.Footer>
          <Button href="/admin/dashboard/themes/browse" variant="secondary" className="hidden lg:block">
            Cancel
          </Button>
          <Button type="submit" isLoading={onSubmitLoading}>{onSubmitBtnText}</Button>
        </FormLayout.Footer>
      </form>
    </FormLayout>
  )
}

export default AdminThemeForm