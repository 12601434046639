import { BaseQueryFn, EndpointBuilder, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'
import { User } from '../../types/models/user'
import UserLoginRequest from '../../types/requests/auth/user/login'
import QueryTags from '../../types/QueryTags'
import AccountRequest from '../../types/requests/auth/user/account'
import MfaSendRequest from '../../types/requests/mfa/sendRequest'
import VerifyAssetOwnerRequest from '../../types/requests/mfa/assetOwnerRequest'
import VerifyAssetDeviceRequest from '../../types/requests/mfa/assetDeviceRequest'
import Device from 'api/types/models/device'
import Asset from 'api/types/models/asset'
import RegisterRequest from 'api/types/requests/auth/user/register'

const userAuthEndpoints = (builder: EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>, QueryTags, 'data'
>) => ({
  getMe: builder.query<{ user: User }, void>({
    query: () => '/auth/user/me',
    providesTags: ['auth'],
  }),

  login: builder.mutation<{ user: User }, UserLoginRequest>({
    query: (body) => ({
      url: '/auth/user/login',
      method: 'post',
      body,
    }),
  }),

  logout: builder.mutation<void, void>({
    query: () => ({
      url: '/auth/logout',
      method: 'post',
    }),
  }),

  register: builder.mutation<{ user: User }, RegisterRequest>({
    query: (body) => ({
      url: '/auth/user/register',
      method: 'post',
      body,
    }),
  }),

  mfaVerifyAssetOwner: builder.mutation<{ user: User }, VerifyAssetOwnerRequest>({
    query: (body) => ({
      url: '/auth/user/mfa/verify/asset-owner',
      method: 'post',
      body,
    }),
  }),

  mfaVerifyAssetDevice: builder.mutation<{ assetDevice: Device, asset: Asset }, VerifyAssetDeviceRequest>({
    query: (body) => ({
      url: '/auth/user/mfa/verify/asset-device',
      method: 'post',
      body,
    }),
  }),

  mfaSend: builder.mutation<void, MfaSendRequest>({
    query: (body) => ({
      url: '/auth/user/mfa/send',
      method: 'post',
      body,
    }),
  }),

  updateAccount: builder.mutation<{ user: User }, AccountRequest>({
    query: ({ id, ...body }) => ({
      url: `/auth/user/${id}`,
      method: 'put',
      body,
    }),
    invalidatesTags: ['asset'],
  }),
})

export default userAuthEndpoints