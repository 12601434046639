import FormHeader from 'components/admin/form_header'
import AdminMessageForm, { AdminMessageFormInputs } from 'components/admin/forms/asset/message'
import Spinner from 'components/spinner'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { DateTime } from 'luxon'
import { WeekDay } from 'enums/WeekDay'
import { useToast } from 'hooks/useToast'
import { useAdminGetMessageQuery, useAdminUpdateMessageMutation } from 'api/endpoints/admin/message'
import { AccessRequestLayout } from 'layouts/AccessRequestLayout'

const AdminMessageManagePage = () => {
  const { messageId } = useParams()
  const { successToast } = useToast()

  const { data: message } = useAdminGetMessageQuery({ id: parseInt(messageId!) })
  const [updateMessage, { isLoading: isUpdateLoading }] = useAdminUpdateMessageMutation()

  const timeFrame = useMemo(() => {
    if (message?.time_frames) {
      return message.time_frames.reduce((acc, curr) => {
        return ({
          startTime: DateTime.fromISO(curr?.start_time as string)?.toFormat('HH:mm'),
          endTime: DateTime.fromISO(curr?.end_time as string)?.toFormat('HH:mm'),
          daysOfWeek: [...acc?.daysOfWeek ?? [], curr?.day_of_week],
        })
      }, {} as { startTime?: string, endTime?: string, daysOfWeek: WeekDay[] })
    }
  }, [message?.time_frames])

  const handleUpdateMessage = async (inputs: AdminMessageFormInputs) => {
    await updateMessage({
      asset_id: message!.asset!.id,
      id: parseInt(messageId!),
      name: inputs.name,
      content: inputs.content,
      is_default: inputs.is_default,
      time_frames: 'days_of_week' in inputs
        ? inputs?.days_of_week?.map(day => ({
          day_of_week: day as WeekDay,
          start_time: inputs?.start_time as string,
          end_time: inputs?.end_time as string,
        }))
        : [],
    })
      .unwrap()
      .then(() => successToast({ message: 'Message has been updated' }))
      .catch(() => { /* Errors directly handled by form */ })
  }

  if (!message) {
    return (
      <div className="flex justify-center p-3">
        <Spinner className="text-primary-gray" />
      </div>
    )
  }

  return (
    <AccessRequestLayout
      userId={message.asset?.user_id}
      blockRedirect={!message.asset?.user_id}
      redirectUrl="/admin/dashboard/assets/browse"
    >
      <div className="flex flex-col h-full">

        <FormHeader
          title="Manage Message"
          description="Manage Message information"
          backBtnText="Back to Asset"
          backBtnUrl={`/admin/dashboard/assets/manage/${message?.asset?.id}`}
        />

        <AdminMessageForm
          message={message}
          isLoading={isUpdateLoading}
          onSubmit={(data) => handleUpdateMessage(data)}
          btnText='Update'
          defaultValues={{
            asset_id: message!.asset!.id,
            name: message?.name ?? '',
            content: message?.content ?? '',
            is_default: message?.is_default ?? false,
            days_of_week: timeFrame?.daysOfWeek ?? [],
            start_time: timeFrame?.startTime ?? '',
            end_time: timeFrame?.endTime ?? '',
          }}
        />

      </div>
    </AccessRequestLayout>
  )
}

export default AdminMessageManagePage