import Asset from 'api/types/models/asset'
import { useAdminGetAssetMapQuery } from 'api'
import classNames from 'classnames'
import MapBounds from 'types/MapBounds'
import { useFilters } from 'hooks/useFilters'
import MapMarkerIcon from 'components/icons/MapMarkerIcon'
import { AdvancedMarker, APIProvider, Map, Marker, Pin } from '@vis.gl/react-google-maps'

type AssetMapProps = {
  assets?: Asset[]
  defaultLat?: number
  defaultLng?: number
  defaultZoom?: number
}

const AssetMap = ({
  defaultLat = 51.5285262, // London
  defaultLng = 0.2663998,
  defaultZoom = 7,
}: AssetMapProps) => {
  const [{ filters }, setFilters] = useFilters<MapBounds>({
    initialFilters: {
      topRight: undefined,
      bottomLeft: undefined,
    },
    opts: {
      useDebounce: false, // false so that the co-ords arent displayed in URL bar
    },
  })

  const handleMapChange = (map: any) => {
    const topRight: { latitude: number, longitude: number } = {
      latitude: map.bounds.ne.lat,
      longitude: map.bounds.ne.lng,
    }

    const bottomLeft: { latitude: number, longitude: number } = {
      latitude: map.bounds.sw.lat,
      longitude: map.bounds.sw.lng,
    }

    setFilters({
      topRight: topRight,
      bottomLeft: bottomLeft,
    })
  }

  const {
    data: assets,
  } = useAdminGetAssetMapQuery({
    ...filters,
  }, { refetchOnMountOrArgChange: true })

  return (
    <div className="flex flex-col gap-2">
      <div className='w-full h-[40rem] bg-green rounded-md overflow-hidden border'>
        {
          process.env.REACT_APP_GOOGLE_MAPS_API_KEY
          ? (
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
              <Map
                mapId={'kicall'}
                defaultCenter={{ lat: defaultLat, lng: defaultLng }}
                defaultZoom={defaultZoom}
              >
                {
                  assets?.map((asset) =>
                    <AdvancedMarker
                      key={asset.id}
                      position={{ lat: parseFloat(asset.latitude!), lng: parseFloat(asset.longitude!) }}
                    >
                      {
                        asset.is_private
                          ? <Pin background="#60a5fa" borderColor="darkBlue" glyphColor="darkBlue" /> 
                          : <Pin />
                      }
                    </AdvancedMarker>
                  )
                }
              </Map>
            </APIProvider>
          ) : (
            <div className="flex h-full">
              <p className="m-auto text-indicator-error">
                There was an error while attempting to showing the map here, please try again later
              </p>
            </div>
          )
        }
      </div>
      <div className="flex gap-2">
        <div className="flex gap-px items-center">
          <MapMarkerIcon className='h-5 w-5 text-blue-400' />
          <span>Public</span>
        </div>
        <div className="flex gap-px items-center">
          <MapMarkerIcon className='h-5 w-5 text-red-400' />
          <span>Private</span>
        </div>
      </div>
    </div>
  )
}

type MapMarkerProps = {
  lat: string
  lng: string
  isPrivate: boolean
}

const MapMarker = ({ isPrivate }: MapMarkerProps) => (
  <MapMarkerIcon
    className={classNames('h-8 w-8 -translate-y-full -translate-x-1/2', isPrivate ? 'text-red-400' : 'text-blue-400')}
  />
)


export default AssetMap