import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import Alert from 'components/alerts'
import FormError from 'components/error'
import { Title } from 'components/headings'
import { useEffect, useState } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useMfaSendMutation } from 'api'
import useErrorHandler from 'hooks/useErrorHandler'
import { RootState } from 'store'
import Button from 'components/button'
import Card from 'components/cards/default'
import NotificationToast from 'components/notifications'
import Input from 'components/form/input'

export type MfaFormInputTypes = {
  masked_uid?: string
  one_time_password: string
  country_code: string
  phone: string
  context: string
}

type Props = {
  assetUid?: string
  phone: string | undefined
  countryCode: string | undefined
  context: string
  onSubmitError?: FetchBaseQueryError | SerializedError | undefined
  onSubmit?: (data: MfaFormInputTypes) => void
  isLoading?: boolean
}

const MfaForm = ({ assetUid = '', phone, countryCode, context, onSubmit, onSubmitError, isLoading = false }: Props) => {
  const errorHandler = useErrorHandler()
  const { notify } = useNotifier()

  const [verificationFailure, setVerificationFailure] = useState({
    type: null,
    message: null,
  })
  const [verificationAttempts, setVerificationAttempts] = useState(null)
  const [showInputForm, setShowInputForm] = useState(true)

  const [sendCode, { 
    error: mfaSendError, 
    isLoading: isMfaSendLoading, 
    isSuccess: isMfaSendSuccessful,
  }] = useMfaSendMutation()

  const { user } = useSelector((state: RootState) => ({
    user: state.auth.user,
  }))

  const { register, setValue, watch, handleSubmit, setError, formState: { errors }, clearErrors } = useForm<MfaFormInputTypes>({
    defaultValues: {
      one_time_password: '',
    },
  })

  useEffect(() => {
    if (onSubmitError) {
      setShowInputForm(false)
      attemptsHandler(onSubmitError)
      errorHandler(onSubmitError, setError)
    }
  }, [onSubmitError])

  useEffect(() => {
    if (mfaSendError) {
      errorHandler(mfaSendError, setError)
    }
  }, [mfaSendError])

  useEffect(() => {
    if (isMfaSendSuccessful) {
      notify(<NotificationToast message="Code sent successfully" />)
    }
  }, [isMfaSendSuccessful])

  const submitWrapper = (data: MfaFormInputTypes) => {
    data.phone = phone ?? user?.phone ?? ''
    data.country_code = countryCode ?? user?.country_code ?? ''
    data.masked_uid = assetUid
    data.context = context

    return onSubmit?.(data)
  }

  const sendMfaCode = () => {
    return sendCode({
      context: context,
      country_code: countryCode ?? '',
      phone: phone ?? undefined,
      masked_uid: assetUid ?? undefined,
    })
  }

  const attemptsHandler = (error: any) => {
    setVerificationAttempts(error.data.errors[0].attemptsRemaining)
    setVerificationFailure({
      type: error.data.errors[0].type,
      message: error.data.errors[0].message,
    })
  }

  const resetForm = () => {
    setValue('one_time_password', '')
    clearErrors('one_time_password')
    setShowInputForm(true)
  }

  return (
    <>
      {
        showInputForm ?
          <form onSubmit={handleSubmit(submitWrapper)} className="h-full">
            <Card className="flex flex-col gap-2">
              <Title>MFA</Title>
              <Alert type="info" message="A secure code has been texted to you, you have 5 minutes to enter the code below." />
              <div className="flex flex-col gap-2 mt-6 mb-12 text-center">
                <Input
                  {...register('one_time_password')}
                  autoFocus 
                  type="text" 
                  maxLength={6}
                  inputMode="numeric"
                  pattern="[0-9]*" 
                  autoComplete="one-time-code" 
                />
                {
                  errors.one_time_password?.message &&
                  <FormError className="mt-1" text={errors.one_time_password?.message} />
                }
                <Button type="button" block variant="link" onClick={() => sendMfaCode()} isLoading={isMfaSendLoading}>Resend Code</Button>
              </div>
              <div className="mt-auto">
                <Button type="submit" block disabled={watch('one_time_password').length < 6} isLoading={isLoading}>Continue</Button>
              </div>
            </Card>

          </form>
          :
          <>
            <Card className="flex flex-col gap-2 text-center">
              <Title>Sorry</Title>
              <div className="max-w-sm mx-auto mt-6 mb-12">
                <Alert type="error" message={
                  <div className="text-base">
                    {
                      verificationFailure.type && verificationFailure.type === 'invalid' ?
                        <>
                          <p>Unfortunately you are unable to view the private message.</p>
                          <p className="mt-6 mb-1 text-2xl font-bold">{verificationAttempts}</p>
                          <p>{verificationAttempts === 1 ? 'attempt' : 'attempts'} Remaining</p>
                        </>
                        :
                        <p>{verificationFailure.message}</p>
                    }
                  </div>
                } />
              </div>
              <Button type="button" block onClick={() => resetForm()}>Try Again</Button>
            </Card>
          </>

      }
    </>

  )
}

export default MfaForm