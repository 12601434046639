import MultiSort from 'api/types/requests/Sort'
import { useState } from 'react'

export const useSort = (initialSorts: MultiSort, onChange?: () => void) => {
  const [sorts, setSorts] = useState<MultiSort>(initialSorts)

  const toggleSorts = (key: string) => {
    setSorts(currentSorts => {
      const newSorts: MultiSort = initialSorts

      for (const orderKey in currentSorts) {
        if(orderKey !== key) {
          newSorts[orderKey] = undefined // reset other fields to undefined
          continue
        }

        if (currentSorts[key] === 'asc') { // if asc, set to desc
          newSorts[key] = 'desc'
        } else if (currentSorts[key] === 'desc') { // if desc, set to undefined
          newSorts[key] = undefined
        } else {
          newSorts[key] = 'asc' // if undefined, set to asc
        }
      }

      onChange?.()
      return newSorts
    })
  }

  return { sorts, toggleSorts }
}