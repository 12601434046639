import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { Banner } from 'components/banner'
import Button from 'components/button'
import { FormControl } from 'components/form/control'
import { Form } from 'components/form/form'
import Input from 'components/form/input'
import { FormLabel } from 'components/form/label'
import Modal from 'components/modals'
import { z } from 'zod'

const verifySchema = z.object({
  code: z.string().min(6, 'Code must be 6 ditgits long').max(6, 'Code must be 6 ditgits long'),
})

export type VerifyAccessRequestFormInputs = z.infer<typeof verifySchema>

type VerifyAccessRequestViewProps = {
  onResend: () => void
  onSubmit: (inputs: VerifyAccessRequestFormInputs) => void
  queryErrors: FetchBaseQueryError | SerializedError | undefined
  onClose: () => void
  isLoading: boolean
  isResending: boolean
}

export function VerifyAccessRequestView({
  onResend,
  onSubmit,
  queryErrors,
  onClose,
  isLoading,
  isResending,
}: VerifyAccessRequestViewProps) {
  return (
    <Form<VerifyAccessRequestFormInputs, typeof verifySchema>
      onSubmit={onSubmit}
      validationSchema={verifySchema}
      error={(queryErrors as FetchBaseQueryError)?.status === 422 ? queryErrors : undefined}
    >
      {({ register, formState: { errors } }) => (
        <>
          <Modal.Content>
            <p>An OTP has been sent to the owner. Please enter it below to verify your request.</p>

            <br />

            <FormControl className="mb-9">
              <FormLabel>One Time Code</FormLabel>
              <Input
                {...register('code')}
                type="number"
                placeholder="Enter code"
                error={errors.code?.message}
              />
              <Button
                block
                type="button"
                variant="link"
                onClick={onResend}
                isLoading={isResending}

              >
                Resend Request
              </Button>
            </FormControl>

            {
              (queryErrors as FetchBaseQueryError)?.status === 429 &&
              <Banner intent="error" className="mb-6">
                <p>Too many unsuccessful attempts! Please try again in a few minutes.</p>
              </Banner>
            }
          </Modal.Content>

          <Modal.Footer>
            <div className="flex justify-between gap-2">
              <Button
                variant="secondary"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                isLoading={isLoading}
              >
                Verify
              </Button>
            </div>
          </Modal.Footer>
        </>
      )}
    </Form>
  )
}