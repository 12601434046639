import classNames from 'classnames'
import TooltipIcon from 'components/icons/TooltipIcon'
import ToolTip from 'components/tooltip'
import { PropsWithChildren, HTMLAttributes, CSSProperties } from 'react'

interface LabelBaseProps extends HTMLAttributes<HTMLLabelElement> {
  htmlFor?: string
  tooltip?: string
  tooltipId?: string
  tooltipStyle?: CSSProperties
}

type FormLabelProps = PropsWithChildren<LabelBaseProps>

export function FormLabel({ children, className, ...props }: FormLabelProps) {
  return (
    <div className="flex items-center gap-1 mb-1">
      <label className={classNames('block mb-[2px] text-sm font-semibold', className)} {...props}>
        {children}
      </label>
      { props.tooltip && props.tooltipId &&
        <ToolTip id={props.tooltipId} content={props.tooltip}>
          <TooltipIcon className='h-4' id={props.tooltipId}/>
        </ToolTip>
      }
    </div>
  )
}