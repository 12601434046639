import Button from 'components/button'
import { useToast } from 'hooks/useToast'
import Card from 'components/cards/default'
import { useNavigate, useParams } from 'react-router-dom'
import ArrowIcon from 'components/icons/ArrowIcon'
import TrashIcon from 'components/icons/TrashIcon'
import { MessageForm, MessageInputs } from 'components/forms/message'
import { useGetMessageQuery, useUpdateMessageMutation } from 'api/endpoints/user/message'
import { DateTime } from 'luxon'
import { WeekDay } from 'enums/WeekDay'
import { useMemo, useState } from 'react'
import { DeleteMessageModal } from 'components/modals/forms/message'

export function AssetManageMessagePage() {
  const navigate = useNavigate()

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)

  const { uid, messageId } = useParams()

  const { successToast } = useToast()

  const { data: message } = useGetMessageQuery(
    { id: parseInt(messageId!) },
    { skip: !messageId }
  )

  const [updateMessage, {
    isLoading: isUpdatingMessage,
    error: updateMessageError,
  }] = useUpdateMessageMutation()

  const handleUpdateMessage = async (inputs: MessageInputs) => {
    await updateMessage({
      asset_id: message!.asset!.id,
      id: parseInt(messageId!),
      name: inputs.name,
      content: inputs.content,
      is_default: inputs.is_default,
      time_frames: 'days_of_week' in inputs
        ? inputs?.days_of_week?.map(day => ({
          day_of_week: day as WeekDay,
          start_time: inputs?.start_time as string,
          end_time: inputs?.end_time as string,
        }))
        : [],
    })
      .unwrap()
      .then(() => successToast({ message: 'Message has been updated' }))
      .catch(() => { /* Errors directly handled by form */ })
  }

  const handleConfirmation = () => {
    setOpenConfirmationModal(false)
    navigate(`/asset/manage/${uid}`)
  }

  const timeFrame = useMemo(() => {
    return message?.time_frames?.reduce((acc, curr) => {
      return ({
        startTime: DateTime.fromISO(curr?.start_time as string)?.toFormat('HH:mm'),
        endTime: DateTime.fromISO(curr?.end_time as string)?.toFormat('HH:mm'),
        daysOfWeek: [...acc?.daysOfWeek ?? [], curr?.day_of_week],
      })
    }, {} as { startTime?: string, endTime?: string, daysOfWeek: WeekDay[] })
  }, [message?.time_frames])

  return (
    <Card>
      <div className="flex justify-between">
        <Button
          href={`/asset/manage/${uid}`}
          type="link"
          variant="secondary"
          icon={<ArrowIcon className="w-5 h-5" />}
          isIconOnly
        />
        {
          !message?.is_default && (
            <Button
              type="button"
              variant="danger"
              icon={<TrashIcon className="w-5 h-5" />}
              onClick={() => setOpenConfirmationModal(true)}
              isIconOnly
            />
          )
        }
      </div>

      <MessageForm
        btnTxt="Update Message"
        errors={updateMessageError}
        isLoading={isUpdatingMessage}
        onSubmit={handleUpdateMessage}
        defaultValues={{
          asset_id: message?.asset?.id,
          name: message?.name ?? '',
          content: message?.content ?? '',
          is_default: message?.is_default ?? false,
          days_of_week: timeFrame?.daysOfWeek ?? [],
          start_time: timeFrame?.startTime ?? '',
          end_time: timeFrame?.endTime ?? '',
        }}
      />

      <DeleteMessageModal
        message={message}
        isOpen={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        onConfirm={handleConfirmation}
      />
    </Card>
  )
}