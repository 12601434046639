import { BaseQueryFn, EndpointBuilder, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query'
import AssetStatistics from 'api/types/models/asset_statistics'
import EventLog from 'api/types/models/event_log'
import Asset from '../../types/models/asset'
import QueryTags from '../../types/QueryTags'
import AssetsGetRequest from '../../types/requests/asset/get'
import { AdminAssetUpdateRequest } from '../../types/requests/asset/update'
import PaginatedResponse from '../../types/requests/PaginatedResponse'
import { stringify } from 'qs'
import MapBounds from 'types/MapBounds'

const adminAssetEndpoints = (builder: EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>, QueryTags, 'data'
>) => ({
  adminGetAssets: builder.query<PaginatedResponse<Asset[]>, AssetsGetRequest>({
    query: ({ page, sort, search, filter }) => ({
      url: '/admin/assets',
      params: {
        ...(page && { page }),
        ...(sort && { sort: stringify(sort) }),
        ...(search && { search }),
        ...(filter && { filter: stringify(filter, { skipNulls: true }) }),
      },
    }),
    providesTags: ['admin_assets'],
  }),

  adminGetAsset: builder.query<Asset, number>({
    query: (id) => ({
      url: `/admin/assets/${id}`,
    }),
    providesTags: ['admin_asset'],
  }),

  adminGetAssetMap: builder.query<Asset[], MapBounds>({
    query: ({ ...params }) => ({
      url: '/admin/assets/map',
      params: {
        topRight: params.topRight ? [params.topRight?.latitude, params.topRight?.longitude] : undefined,
        bottomLeft: params.bottomLeft ? [params.bottomLeft?.latitude, params.bottomLeft?.longitude] : undefined,
      },
    }),
  }),

  adminUpdateAsset: builder.mutation<Asset, AdminAssetUpdateRequest>({
    query: ({ id, ...body }) => ({
      url: `/admin/assets/${id}`,
      method: 'PUT',
      body,
    }),
    invalidatesTags: ['admin_assets', 'admin_asset'],
  }),

  adminDeregisterAsset: builder.mutation<{ asset: Asset }, { id: number }>({
    query: ({ id }) => ({
      url: `/admin/assets/${id}/deregister`,
      method: 'PUT',
    }),
    invalidatesTags: ['admin_assets', 'admin_asset'],
  }),

  adminGetAssetEvents: builder.query<{ eventLog: PaginatedResponse<EventLog[]>, statistics: AssetStatistics }, { id: string, page?: number }>({
    query: ({ id, page }) => ({
      url: `/asset/${id}/events`,
      params: {
        ...(page && { page }),
      },
    }),
  }),
})

export default adminAssetEndpoints