import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useAdminLoginMutation } from 'api'
import Button from 'components/button'
import Card from 'components/cards/default'
import Input from 'components/form/input'
import useErrorHandler from 'hooks/useErrorHandler'
import { TfaCodes } from 'api/types/response/auth/admin/login'
import { useState } from 'react'

type FormInputs = {
  email: string
  password: string
}

const AdminLoginPage = () => {
  const navigate = useNavigate()
  const errorHandler = useErrorHandler()

  const { register, handleSubmit, setError, formState: { errors } } = useForm<FormInputs>()
  const [submitLogin, { isLoading: isLoginLoading }] = useAdminLoginMutation()
  const [attemptsRemaining, setAttemptsRemaining] = useState<null|number>(null)
  const [retryAfter, setRetryAfter] = useState<null|number>(null)

  const onSubmit = async (data: FormInputs) => {
    await submitLogin({
      ...data,
    })
      .unwrap()
      .then((response) => {
        if (response.data.code === TfaCodes.RequiresSetup) {
          navigate('/admin/tfa/setup', {
            state: {
              tfa_setup: true,
            },
          })
        }
        if (response.data.code === TfaCodes.RequiresVerification) {
          navigate('/admin/tfa', {
            state: {
              tfa_challenge: true,
            },
          })
        }
      })
      .catch((error) => {
        setAttemptsRemaining(error?.data?.remaining_attempts || null)

        if (error?.data?.retry_after !== null) {
          setRetryAfter(error.data.retry_after)
        }

        errorHandler(error, setError)
      })
  }

  return (
    <div className="h-full p-3 bg-slate-200 lg:p-0">
      <div className="flex h-full max-w-md mx-auto">
        <Card className="my-auto shadow-sm">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-y-10">
              <div className="flex flex-col gap-y-4">
                <p className="my-auto text-lg font-semibold">
                  Login
                </p>
                <div className="space-y-2">
                  <Input {...register('email')} type="email" label="Email" error={errors.email?.message} required />
                  <Input {...register('password')} type="password" label="Password" error={errors.password?.message} required />
                  {
                    attemptsRemaining && (
                      <div className="bg-red-500 py-2 px-4 rounded-md">
                        <h5 className="font-semibold text-white text-center">
                          {attemptsRemaining} {attemptsRemaining > 1 ? 'attempts' : 'attempt'} remaining
                        </h5>
                      </div>
                    )
                  }
                  {
                    retryAfter && !attemptsRemaining && (
                      <div className="bg-red-500 py-2 px-4 rounded-md">
                        <h5 className="font-semibold text-white text-center">
                          Retry in {retryAfter} {retryAfter > 1 ? 'minutes' : 'minute'}
                        </h5>
                      </div>
                    )
                  }
                </div>
              </div>
              <div className="flex flex-row justify-end gap-2">
                <Button variant="secondary" href="/">
                  Leave
                </Button>
                <Button type="submit" isLoading={isLoginLoading}>
                  Login
                </Button>
              </div>
            </div>
          </form>
        </Card>
      </div>
    </div>
  )
}

export default AdminLoginPage