import { useState } from 'react'
import EulaModal from './modals/EulaModal'
import PrivacyAndCookiesModal from './modals/PrivacyAndCookiesModal'
import TermsAndConditions from './modals/TermsAndConditionsModal'
import { DateTime } from 'luxon'

const Footer = () => {
  const [isTcModalOpen, setTcModalOpen] = useState<boolean>(false)
  const [isPrivacyModalOpen, setPrivacyModalOpen] = useState<boolean>(false)
  const [isEulaModalOpen, setEulaModalOpen] = useState<boolean>(false)
  const currentYear = DateTime.now().toFormat('yyyy')

  const TermsConditions = () => (
    <span onClick={() => setTcModalOpen(true)} className="cursor-pointer">
      T&Cs
    </span>
  )

  const Cookies = () => (
    <span onClick={() => setPrivacyModalOpen(true)} className="cursor-pointer">
      Cookies
    </span>
  )

  const SupportEmail = () => (
    <a href="mailto:support@kicall.co.uk" className="cursor-pointer underline">
      Support
    </a>
  )

  const EulaInformation = () => (
    <span className="cursor-pointer" onClick={() => setEulaModalOpen(true)}>
      © {currentYear} Supra (UK) LTD
    </span>
  )

  const VersionInformation = () => (
    <span>
      {process.env.REACT_APP_VERSION ?? '1.0.0'}
    </span>
  )

  return (
    <>
      <div className="flex flex-wrap gap-1 px-2 py-1 text-xxs lg:text-sm bg-slate-200 opacity-50 rounded-tr-md">
        <TermsConditions /> | <Cookies /> | <SupportEmail /> | <EulaInformation /> | <VersionInformation />
      </div>

      <TermsAndConditions isOpen={isTcModalOpen} onClose={() => setTcModalOpen(false)} />
      <PrivacyAndCookiesModal isOpen={isPrivacyModalOpen} onClose={() => setPrivacyModalOpen(false)} />
      <EulaModal isOpen={isEulaModalOpen} onClose={() => setEulaModalOpen(false)} />
    </>
  )
}

export default Footer